import React from "react";
// Customizable Area Start
import { Box, Button, Typography, Container, IconButton, styled } from "@material-ui/core";
import Header from "../../../components/src/Header.web";
// Customizable Area End
import LandingPageController, {
  Props,
} from "./LandingPageController";
import { cloudDone, organized, recipeBanner, outlineCheck, recipeBannerEmpty, recipeDesign1, recipeDesign2, recipeDesign3, recipeDesign4, recipeDesign5, recipeFeatureBg, share, uploadImage, downArrow, recipeDesign6, recipeDesign8, recipeDesign9, recipeDesign10 } from "./assets";
import Footer from "../../../components/src/Footer.web";

export default class RecipsPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { recipeFaq } = this.state
    return (
      //Merge Engine DefaultContainer
      <Box>
        <Header data-test-id="header" onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick} />
        <Box style={webStyles.backgroundImage}>
        <Box style={{ width: "100%", maxWidth: "666px" }}>
            <Typography style={webStyles.HeaderText}>Family Tree</Typography>
            <Typography style={webStyles.subHeader}>
            Creating your own family tree is about preserving your family history and the memories of your family members. Your family tree tells a family story that started as the roots and continues to unfold as your family tree grows. 
            </Typography>
            <Button type="submit" style={webStyles.continueButton}>
              <Typography style={webStyles.continueText as any}>Get started</Typography>
            </Button>
          </Box>
        </Box>
        <Box>
          <Box style={{ display: "flex", gap: 80, justifyContent: 'center', marginTop: 60, position: 'relative' }}>
            <Box style={{ width: "100%", maxWidth: "656px" }}>
              <img src={recipeDesign5} style={{ width: "100%", maxWidth: "297px", position: 'absolute', right: 194, top: -120 }} />
              <img src={recipeDesign1} style={{ width: "100%", maxWidth: "297px", position: 'absolute', left: 0, top: -100, zIndex: -1 }} />
              <img src={recipeDesign3} style={{ width: "100%", maxWidth: "342px", position: 'absolute', right: 0, top: -470, height: "356px" }} />
              <Typography style={webStyles.familyText as React.CSSProperties}>
                Family Recipes Preservation
              </Typography>
              <Typography style={{ fontWeight: 400, fontFamily: "Poppins", fontSize: 20, color: "#1C2110" }}>Preserving family recipes is important. Not only do the flavors and smells transport us back to our own childhood, but they—like pictures—often have a story to tell. They evoke wonderful memories of those who wrote them down and keep traditions alive.
                MyKinship is a digital service that allows you to preserve your family recipes while capturing, sharing, and organizing family photos, immortalizing memories, and creating your own family
              </Typography>

            </Box>
            <img src={recipeBannerEmpty} style={{ maxWidth: "605px", width: "100%", zIndex: 9999 }} />
          </Box>
        </Box>
        <Box style={{ position: "relative" }}>
          <Box style={{ display: "flex", gap: 80, justifyContent: 'center', marginTop: 150 }}>
            <img src={recipeDesign2} style={{ width: "100%", maxWidth: "230px", position: 'absolute', left: 0, top: -280, height: 320 }} />
            <img src={recipeDesign6} style={{ width: "100%", maxWidth: "230px", position: 'absolute', right: 0, height: 270, top: 300 }} />
            <img src={recipeBannerEmpty} style={{ maxWidth: "605px", width: "100%", height: 450 }} alt="Recipe Banner" />
            <Box style={webStyles.textContainer}>

              <Box>
                <Typography style={webStyles.familyText2 as React.CSSProperties}>
                  Preserving Family Recipes
                </Typography>
              </Box>
              <Typography style={webStyles.contentText as React.CSSProperties}>
                Most family recipes were written on paper, an organic material that deteriorates with time and becomes increasingly delicate. Storing it indefinitely is not an option without special equipment, but MyKinship can make it possible. It works as a recipe organizer and meal planner. It also offers recipe management—turning handwritten recipes into digital recipes for easier storage.
                There is no longer a need for a recipe box filled with scraps of paper. Those recipe books that have been passed down through generations can now be digitized before their knowledge is finally gone.
              </Typography>
            </Box>

          </Box>
        </Box>
        <RecipePageStyle style={{display:'flex',justifyContent:'center', marginTop:60}}>
          <Box className="priceMainBox">
            {this.state.subscriptionData.map(plan => (
              <Box key={plan.id} className="priceBox">
                <Typography className="priceTitle">{plan.name}</Typography>
                <Typography className="priceSubTitle" dangerouslySetInnerHTML={{ __html: plan.details }} />
                <Box className="priceCenterDiv">
                  <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography className="dollar"> $ </Typography>
                    <Typography className="priceTag"> {plan.price}</Typography>
                  </Box>
                  <Typography className="monthText">/ Month</Typography>
                </Box>
                <Typography className="storageText"><img src={outlineCheck} alt="check icon" /> {plan.storage} {plan.storage_unit} of storage</Typography>
                <Button className="startBtn" data-test-id={`priceBtn${plan.id}`} onClick={this.startNowBtn}>Start now</Button>
              </Box>
            ))}

          </Box>
        </RecipePageStyle>
        <Box style={{ display: 'flex', justifyContent: 'center', marginTop: 100, position: "relative" }}>
          <Box style={webStyles.backgroundImageFeature as any}>
            <img src={recipeDesign8} style={{ width: "100%", maxWidth: "229px", position: 'absolute', right: 0, top: -80, height: 242 }} />
            <img src={recipeDesign2} style={{ width: "100%", maxWidth: "115px", position: 'absolute', left: 0, top: 80, height: 122 }} />
            <img src={recipeDesign10} style={{ width: "100%", maxWidth: "180px", position: 'absolute', right: 0, top: 300, height: 352 }} />

            <Typography style={webStyles.bestFeatures}>The Best Features For Your Recipes</Typography>
            <Typography style={webStyles.bestFeatures2 as any}>MyKinship works as a recipe organizer and meal planner and a recipe management
              turning handwritten recipes into digital recipes for easier storage.</Typography>
            <Box style={{ width: "100%", maxWidth: "950px", backgroundColor: '#FFFFFF', height: 180, marginTop: 40, display: 'flex', position: 'relative' }}>
              <img src={uploadImage} style={{ width: 60, height: 60, margin: "auto", padding: 20 }} />
              <Box>
                <Typography style={{ fontWeight: 700, fontSize: 48, fontFamily: 'Poppins', color: "#305328" }}>Easy Recipe Uploads</Typography>
                <Typography style={{ fontWeight: 400, fontSize: 20, fontFamily: "Poppins" }}>Quickly upload your recipes by typing them into our app, uploading photos, or importing from the web. </Typography>
                <img src={recipeDesign9} style={{ width: "100%", maxWidth: "152px", position: 'absolute', right: 0, height: 142, top: 130, zIndex: "-1" }} />
              </Box>
            </Box>
            <Box style={{ width: "100%", maxWidth: "950px", backgroundColor: '#FFFFFF', height: 180, marginTop: 40, display: 'flex', position: 'relative' }}>
              <img src={organized} style={{ width: 60, height: 60, margin: "auto", padding: 20 }} />
              <Box>
                <Typography style={{ fontWeight: 700, fontSize: 48, fontFamily: 'Poppins', color: "#305328" }}>Organized Recipe Collections</Typography>
                <Typography style={{ fontWeight: 400, fontSize: 20, fontFamily: "Poppins" }}>Create and manage recipe collections easily. Organize by meal type, ingredients, or family member for quick access.</Typography>
                <img src={recipeDesign9} style={{ width: "100%", maxWidth: "152px", position: 'absolute', left: 0, height: 142, top: 130, zIndex: "-1" }} />

              </Box>
            </Box>
            <Box style={{ width: "100%", maxWidth: "950px", height: 180, marginTop: 40, display: 'flex', backgroundColor: '#FFFFFF', position: 'relative' }}>
              <img src={share} style={{ width: 60, padding: 20,height: 60, margin: "auto" }} />
              <Box>
                <Typography style={{ fontWeight: 700, fontFamily: 'Poppins', fontSize: 48, color: "#305328" }}>Sharing Family Recipes</Typography>
                <Typography style={{ fontWeight: 400, fontSize: 20, fontFamily: "Poppins" }}>Share your recipes with family and friends securely. Control who sees your recipes with customizable sharing options.</Typography>
                <img src={recipeDesign9} style={{ width: "100%", maxWidth: "152px", position: 'absolute', right: 0, height: 142, top: 130, zIndex: "-1" }} />

              </Box>
            </Box>
            <Box style={{ width: "100%", maxWidth: "950px", backgroundColor: '#FFFFFF', height: 180, marginTop: 40, display: 'flex', position: 'relative' }}>
              <img src={cloudDone} style={{ width: 60, height: 60, margin: "auto", padding: 20 }} />
              <Box>
                <Typography style={{ fontWeight: 700, color: "#305328", fontSize: 48, fontFamily: 'Poppins' }}>Secure Cloud Storage</Typography>
                <Typography style={{ fontSize: 20,fontWeight: 400, fontFamily: "Poppins" }}>All your recipes are stored securely in the cloud, protected by robust encryption and regular backups.</Typography>
                <img src={recipeDesign9} style={{ width: "100%", maxWidth: "152px", position: 'absolute', left: 0, height: 142, top: 130, zIndex: "-1" }} />

              </Box>
            </Box>
          </Box>

        </Box>
        <Box style={{ maxWidth: 1240, width: '100%', margin: 'auto' }}>
          <Typography style={webStyles.frequentlyTextRecipe}>Frequently asked questions</Typography>
          <Typography style={webStyles.simpleTextRecipe}>Simple, transparent pricing that grows with you. Try any plan free for 30 days.</Typography>
        </Box>
        <Container maxWidth="md" style={{ marginBottom: 100, marginTop: 60 }}>
          <div style={{marginTop: 50, width: '100%', maxWidth: 920, margin: 'auto' }}>

            {[1, 2, 3].map((index: any) => <Box
              style={{
                marginBottom: '16px',
                borderRadius: '4px',
              }}
            >
              <Box
                style={{
                  alignItems: 'center',
                  padding: '12px',
                  display: 'flex',
                  cursor: 'pointer',
                  borderBottom: !this.state.recipeFaq ? '1px solid #D6D3D1' : "none",
                }}
                data-test-id="coveracordian1"
                onClick={this.handleAccordian}
              >
                <Typography style={{ flex: 1, fontFamily: 'Poppins', fontWeight: 700, fontSize: 18 }}>Is there a free trial available?</Typography>
                <IconButton
                  onClick={this.handleAccordian}
                  size="small"
                  data-test-id="coveracordian2"
                  aria-expanded={recipeFaq}
                  aria-label="show more"
                  style={{ marginLeft: 'auto', transition: 'transform 0.3s ease' }}
                >

                  <img src={downArrow} style={{ width: 24, transform: 'rotate(0deg)' }} onClick={this.handleAccordian} />
                </IconButton>
              </Box>
              {recipeFaq && (
                <Box style={{ padding: '12px', borderBottom: this.state.recipeFaq ? '1px solid #D6D3D1' : "none", }}>
                  <Typography style={{ flex: 1, fontFamily: 'Poppins', fontWeight: 400, fontSize: 16, color: '#5D6063' }}>
                    Yes, you can try us for free for 5 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.
                  </Typography>
                </Box>
              )}
            </Box>)}
          </div>
        </Container>
        <Box style={{ position: 'relative' }}>
          <Box style={{ maxWidth: 1240, width: '100%', margin: 'auto' }}>
            <img src={recipeDesign4} style={{ objectFit: 'contain', position: 'absolute', right: 0, height: 420, top: -80, zIndex: -1 }} />
            <Typography style={webStyles.moreQuestions}>More Questions about Saving Family Recipes?</Typography>
            <Typography style={webStyles.moreQuestions2}>Sign up today to immortalize your family recipes. If you have any additional questions about preserving family recipes and immortalizing memories, please don’t hesitate to contact MyKinship.</Typography>
          </Box>
        </Box>
        <Footer />

      </Box>

      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    width: "32rem",
    gap: "2rem"
  },
  HeaderText: {
    fontSize: 72,
    fontWeight: 400,
    fontFamily: 'Abygaer',
    color: '#305328',
    marginTop: 130,
  },
  subHeader: {
    color: '#305328',
    fontWeight: 400,
    fontSize: 24,
    fontFamily: "Poppins"
  },
  continueButton: {
    backgroundColor: "#8CA74B",
    height: '56px',
    borderRadius: '8px',
    width: '292px',
    marginTop: '32px',
  },
  continueText: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    textTransform: 'none'
  },
  familyText: {
    color: "#305328",
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: 48
  },
  familyText2: {
    color: "#305328",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: 48,
    textAlign: 'right',
  },
  contentText: {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: 20,
    color: "#1C2110"
  },
  textContainer: {
    width: "100%",
    maxWidth: "656px"
  },
  backgroundImageFeature: {
    backgroundImage: `url(${recipeFeatureBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    display: 'flex',
    height: '1100px',
    flexDirection: "column",
    alignItems: 'center',
  },
  bestFeatures: {
    color: "#305328",
    fontFamily: "Poppins",
    fontWeight: 700,
    marginTop: 30,
    fontSize: 48,
  },
  backgroundImage: {
    backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 70%), url(${recipeBanner})`,
    backgroundSize: 'cover',
    width: '100%',
    maxWidth: "1635px",
    height: '614px',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: "end",
  },
  bestFeatures2: {
    color: "#5D6063",
    fontFamily: "Poppins",
    fontWeight: 400,
    width: "100%",
    fontSize: 20,
    textAlign: 'center',
    maxWidth: "900px"
  },
  frequentlyTextRecipe: {
    fontSize: 36,
    fontWeight: 700,
    color: '#1C1917',
    marginTop: 100,
    fontFamily: 'Poppins',
  },
  simpleTextRecipe: {
    fontSize: 18,
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#5D6063'
  },
  moreQuestions: {
    fontWeight: 700,
    fontSize: 36,
    fontFamily: "Poppins",
    color: "#1C1917"
  },
  moreQuestions2: {
    fontWeight: 400,
    fontSize: 18,
    fontFamily: "Poppins",
    color: "#5D6063",
    marginTop: 10,
    marginBottom: 195
  },
};

const RecipePageStyle = styled("div")({

  "& .priceMainBox": {
    justifyContent: 'space-between',
    display: 'flex',
    width: '94%',
  },
  "& .priceBox": {
    borderRadius: '16px',
    width: '392',
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
  },
  "& .priceTitle": {
    lineHeight: "44px",
    fontWeight: 700,
    fontSize: "36px",
    fontFamily: 'Poppins',
    marginTop: '40px',
    color: '#1C2110',
    letterSpacing: '-1%',
    marginBottom: '5px',
    textAlign: 'center'
  },
  "& .priceSubTitle": {
    lineHeight: "26px",
    fontWeight: 400,
    fontSize: "18px",
    fontFamily: 'Poppins',
    color: '#1C2110',
    marginBottom: '20px',
    textAlign: 'center'
  },
  "& .priceCenterDiv": {
    backgroundColor: "#8CA74B",
    textAlign: 'center',
    height: '140px',
  },

  "& .priceCenterBox": {
    backgroundColor: "#557C29",
    height: '140px'
  },
  "& .priceTag": {
    lineHeight: "56px",
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: "64px",
    color: '#FFFFFF',
    textAlign: 'center',
    letterSpacing: '-1.5%',
    padding: '13px',
  },
  "& .dollar": {
    lineHeight: "56px",
    fontFamily: 'Inter',
    fontWeight: 300,
    fontSize: "32px",
    color: '#FFFFFF',
    letterSpacing: '-1.5%',
  },
  "& .monthText": {
    lineHeight: "28px",
    fontWeight: 400,
    fontSize: "20px",
    color: '#FFFFFF',
    marginBottom: '20px',
    fontFamily: 'Poppins',
    textAlign: 'center',
    display: "block",
    alignItems: "center",
  },
  "& .storageText": {
    lineHeight: "28px",
    fontWeight: 400,
    fontSize: "20px",
    marginBottom: '40px',
    fontFamily: 'Poppins',
    color: '#1C2110',
    textAlign: 'center',
    marginTop: '20px',
  },
  "& .startBtn": {
    backgroundColor: '#8CA74B',
    borderRadius: '8px',
    marginTop: '20px',
    marginBottom: '48px',
    padding: '10px',
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    width: '182px',
    "& .Muibutton-label": {
      lineHeight: "24px",
      textAlign: 'center',
      fontWeight: 700,
      fontSize: "16px",
      fontFamily: 'Poppins',
      color: '#FFFFFF',
    }
  }
})




// Customizable Area End
