import React from "react";
// Customizable Area Start
import { Box, Button, Typography, Container, IconButton, styled, Card, CardContent } from "@material-ui/core";
import Header from "../../../components/src/Header.web";
// Customizable Area End
import LandingPageController, {
    Props,
} from "./LandingPageController";
import { shareBeach, uploadFilesImage, photosBanner, outlineCheck, recipeDesign1, recipeDesign2, recipeDesign3, recipeDesign4, recipeDesign5, recipeFeatureBg, downArrow, recipeDesign8, recipeDesign10, photosImage, familyTreePin } from "./assets";
import Footer from "../../../components/src/Footer.web";

export default class PhotosPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { recipeFaq } = this.state
        return (
            //Merge Engine DefaultContainer
            <Box>
                <Header data-test-id="header" onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick} />
                <Box style={webStyles.backgroundImage}>
                    <Box style={{ width: "100%", maxWidth: "666px" }}>
                        <Typography style={webStyles.HeaderText}>Family Photo Storage</Typography>
                        <Typography style={webStyles.subHeader}>
                            Capture and share your greatest family memories in one place you can access using most devices. Get industry-leading amounts of storage to prevent anything from being left behind. Preserve every lake trip, every beloved pet, and every child’s milestone. The tools you need to keep it safe are at MyKinship.
                        </Typography>
                        <Button type="submit" style={webStyles.continueButton}>
                            <Typography style={webStyles.continueText as any}>Get started</Typography>
                        </Button>
                    </Box>
                </Box>
                <Box>
                    <Box style={{ display: "flex", gap: 80, justifyContent: 'center', marginTop: 60, position: 'relative' }}>

                        <img src={recipeDesign5} style={{ width: "100%", maxWidth: "297px", position: 'absolute', right: 194, top: -120, zIndex: "-1" }} />
                        <img src={recipeDesign1} style={{ width: "100%", maxWidth: "297px", position: 'absolute', left: 0, top: -100, zIndex: -1 }} />
                        <img src={recipeDesign3} style={{ width: "100%", maxWidth: "342px", position: 'absolute', right: 0, top: -470, height: "356px" }} />

                        <Card style={{ width: "100%", maxWidth: "1133px", color: "#fff", borderRadius: 16, backgroundColor: "#fff", padding: 30, boxShadow: "0 4px 8px rgba(0.5, 0.5, 0.5, 0.5)" }}>
                            <CardContent>
                                <Typography style={{ fontWeight: 700, fontFamily: "Poppins", fontSize: 48, color: "#305328", textAlign: "center" }}>
                                    Capture, Store & Share
                                </Typography>
                                <Typography style={{ fontWeight: 400, fontFamily: "Poppins", fontSize: 20, color: "1C2110" }}>
                                    MyKinship provides families with a secure and easy way to capture, store, and share all the greatest memories with their loved ones. Gone are the days of external Hard Drives and SSDs with limited storage. With MyKinship, you can save all your digital photos on an online photo storage with the amount of storage space you choose.
                                    If photo books were the original solution, MyKinship’s photo cloud storage services would be the next evolutionary step. Take advantage of all the technology that the future has to offer.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
               
                <PhotoPageStyle style={{ display: 'flex', justifyContent: 'center', marginTop: 60 }}>
                    <Box className="priceMainBox2">
                        {this.state.subscriptionData.map(plan => (
                            <Box key={plan.id} className="priceBox2">
                                <Typography className="priceTitle2">{plan.name}</Typography>
                                <Typography className="priceSubTitle2" dangerouslySetInnerHTML={{ __html: plan.details }} />
                                <Box className="priceCenterDiv2">
                                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Typography className="dollar2"> $ </Typography>
                                        <Typography className="priceTag2"> {plan.price}</Typography>
                                    </Box>
                                    <Typography className="monthText2">/ Month</Typography>
                                </Box>
                                <Typography className="storageText2"><img src={outlineCheck} alt="check icon" /> {plan.storage} {plan.storage_unit} of storage</Typography>
                                <Button className="startBtn2" data-test-id={`priceBtn${plan.id}`} onClick={this.startNowBtn}>Start now</Button>
                            </Box>
                        ))}

                    </Box>
                </PhotoPageStyle>
                <Box style={{ display: 'flex', justifyContent: 'center', marginTop: 100, position: "relative" }}>
                    <Box style={webStyles.backgroundImageFeature as any}>
                    <img src={recipeDesign8} style={{ width: "100%", maxWidth: "229px", position: 'absolute', right: 0, top: -80, height: 242 }} />
                        <img src={recipeDesign2} style={{ width: "100%", maxWidth: "115px", position: 'absolute', left: 0, top: 80, height: 122 }} />
                        <img src={recipeDesign10} style={{ width: "100%", maxWidth: "180px", position: 'absolute', right: 0, top: 700, height: 352 }} />
                        <img src={familyTreePin} style={{ width: "100%", maxWidth: "390px", position: 'absolute', left: 0, top: 1800}} />
                        <Typography style={webStyles.bestFeatures}>How Our Family Photo Storage Works</Typography>
                        <Typography style={webStyles.bestFeatures2 as any}>MyKinship offers a seamless and secure way to manage your family’s digital photo collection. Here’s how it works</Typography>
                        <Box style={{ display: "flex", marginTop: "100", maxWidth: "1300px", width: "100%" }}>
                            <img src={uploadFilesImage} style={{ width: "604px" }} />
                            <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: 50, marginTop:130 }}>
                                <Typography style={{ fontSize: 36, fontWeight: 700, fontFamily: "Poppins" }}>Easy Uploads</Typography>
                                <Typography style={{ fontSize: 24, fontWeight: 400, fontFamily: "Poppins", marginTop: 10 }}>Lorem ipsum dolor sit amet consectetur. Urna id diam sed amet quam ipsum fusce. Mattis tempus ultrices sed iaculis a sit viverra quisque morbi. Molestie tincidunt magna faucibus integer volutpat nisi sed a. Odio risus est felis ultrices convallis. Id lacus quis sed lacus nulla urna tellus bibendum. Tortor lacus duis erat mauris dolor et.
                                    Enim eget nullam tincidunt ultrices sed donec.</Typography>
                            </Box>
                        </Box>
                        
                        <Box style={{ display: "flex", marginTop: "100", maxWidth: "1300px", width: "100%" }}>
                           
                            <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: 50, marginTop:130 }}>
                                <Typography style={{ fontSize: 36, fontWeight: 700, fontFamily: "Poppins" }}>Organized Albums</Typography>
                                <Typography style={{ fontSize: 24, fontWeight: 400, fontFamily: "Poppins", marginTop: 10 }}>Lorem ipsum dolor sit amet consectetur. Urna id diam sed amet quam ipsum fusce. Mattis tempus ultrices sed iaculis a sit viverra quisque morbi. Molestie tincidunt magna faucibus integer volutpat nisi sed a. Odio risus est felis ultrices convallis. Id lacus quis sed lacus nulla urna tellus bibendum. Tortor lacus duis erat mauris dolor et.
                                    Enim eget nullam tincidunt ultrices sed donec.</Typography>
                            </Box>
                            <img src={photosImage} style={{ width: "604px" }} />
                        </Box>
                        <Box style={{ display: "flex", marginTop: "100", maxWidth: "1300px", width: "100%" }}>
                            <img src={shareBeach} style={{ width: "604px", zIndex:10 }} />
                            <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: 50, marginTop:130 }}>
                                <Typography style={{ fontSize: 36, fontWeight: 700, fontFamily: "Poppins" }}>Secure Sharing</Typography>
                                <Typography style={{ fontSize: 24, fontWeight: 400, fontFamily: "Poppins", marginTop: 10 }}>Lorem ipsum dolor sit amet consectetur. Urna id diam sed amet quam ipsum fusce. Mattis tempus ultrices sed iaculis a sit viverra quisque morbi. Molestie tincidunt magna faucibus integer volutpat nisi sed a. Odio risus est felis ultrices convallis. Id lacus quis sed lacus nulla urna tellus bibendum. Tortor lacus duis erat mauris dolor et.
                                    Enim eget nullam tincidunt ultrices sed donec.</Typography>
                            </Box>
                        </Box>
                        
                    </Box>


                </Box>
                <Box style={webStyles.frequentlyBox}>
                    <Typography style={webStyles.frequentlyText2}>Frequently asked questions</Typography>
                    <Typography style={webStyles.simpleText}>Here are some of the most frequently asked questions about MyKinship’s Family Photo Storage Solutions.</Typography>
                </Box>
                <Container maxWidth="md" style={{ marginBottom: 100, marginTop: 60 }}>
                    <div style={{ width: '100%', marginTop: 50, maxWidth: 920, margin: 'auto' }}>

                        {[1, 2, 3].map((index: any) => <Box
                            style={{
                                borderRadius: '4px',
                                marginBottom: '16px',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '12px',
                                    cursor: 'pointer',
                                    borderBottom: !this.state.recipeFaq ? '1px solid #D6D3D1' : "none",
                                }}
                                onClick={this.handleAccordian}
                                data-test-id="coveracordian1"
                            >
                                <Typography style={{ flex: 1, fontFamily: 'Poppins', fontWeight: 700, fontSize: 18 }}>Is there a free trial available?</Typography>
                                <IconButton
                                    size="small"
                                    onClick={this.handleAccordian}
                                    data-test-id="coveracordian2"
                                    aria-expanded={recipeFaq}
                                    style={{ marginLeft: 'auto', transition: 'transform 0.3s ease' }}
                                    aria-label="show more"
                                >
                                    <img src={downArrow} style={{ width: 24, transform: 'rotate(0deg)' }} onClick={this.handleAccordian} />
                                </IconButton>
                            </Box>
                            {recipeFaq && (
                                <Box style={{ padding: '12px', borderBottom: this.state.recipeFaq ? '1px solid #D6D3D1' : "none", }}>
                                    <Typography style={{ flex: 1, fontFamily: 'Poppins', fontWeight: 400, fontSize: 16, color: '#5D6063' }}>
                                        Yes, you can try us for free for 5 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.
                                    </Typography>
                                </Box>
                            )}
                        </Box>)}
                    </div>
                </Container>
                <Box style={{ position: 'relative' }}>
                    <Box style={{ maxWidth: 1240, width: '100%', margin: 'auto' }}>
                        <img src={recipeDesign4} style={{ objectFit: 'contain', position: 'absolute', right: 0, height: 420, top: -80, zIndex: -1 }} />
                        <Typography style={webStyles.moreQuestions}>Do You Have More Questions about Our Family Photo Storage Online?</Typography>
                        <Typography style={webStyles.moreQuestions2}>MyKinship is an online digital storage service that specializes in organizing and preserving your family photos. If you have any additional questions regarding our service, don’t hesitate to contact us. </Typography>
                    </Box>
                </Box>
                <Footer />

            </Box>

            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    container: {
        display: "grid",
        gridTemplateColumns: "3fr 7fr",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        padding: "0.5rem 1rem",
        minHeight: "12rem",
        gap: "2rem",
        width: "32rem",
    },
    HeaderText: {
        fontSize: 72,
        fontWeight: 400,
        color: '#305328',
        fontFamily: 'Abygaer',
        marginTop: 130,
    },
    subHeader: {
        color: '#305328',
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: 24,
    },
    continueButton: {
        backgroundColor: "#8CA74B",
        width: '292px',
        borderRadius: '8px',
        height: '56px',
        marginTop: '32px',
    },
    continueText: {
        fontFamily: "Poppins",
        lineHeight: "24px",
        fontWeight: 700,
        fontSize: "16px",
        color: "#FFFFFF",
        textTransform: 'none'
    },
    familyText: {
        color: "#305328",
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: 48
    },
    familyText2: {
        color: "#305328",
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: 48,
        textAlign: 'right',
    },
    contentText: {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: 20,
        color: "#1C2110"
    },
    textContainer: {
        maxWidth: "656px",
        width: "100%",
    },
    backgroundImageFeature: {
        backgroundImage: `url(${recipeFeatureBg})`,
        backgroundPosition: 'center',
        width: '100%',
        height: '2070px',
        display: 'flex',
        backgroundSize: 'cover',
        flexDirection: "column",
        alignItems: 'center',
    },
    bestFeatures: {
        color: "#305328",
        fontWeight: 700,
        fontSize: 48,
        fontFamily: "Poppins",
        marginTop: 30
    },
    backgroundImage: {
        backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 70%), url(${photosBanner})`,
        backgroundSize: 'cover',
        width: '100%',
        maxWidth: "1635px",
        backgroundPosition: 'center',
        height: '850px',
        display: 'flex',
        justifyContent: "end",
    },
    frequentlyBox:{ maxWidth: 1240, margin: 'auto',width: '100%' },
    bestFeatures2: {
        color: "#5D6063",
        width: "100%",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: 20,
        maxWidth: "1200px"
    },
    frequentlyText2: {
        fontSize: 36,
        fontFamily: 'Poppins',
        fontWeight: 700,
        color: '#1C1917',
        marginTop: 100
    },
    simpleText: {
        fontFamily: 'Poppins',
        fontSize: 18,
        fontWeight: 400,
        color: '#5D6063'
    },
    moreQuestions: {
        fontFamily: "Poppins",
        fontSize: 36,
        fontWeight: 700,
        color: "#1C1917"
    },
    moreQuestions2: {
        fontWeight: 400,
        fontSize: 18,
        color: "#5D6063",
        fontFamily: "Poppins",
        marginTop: 10,
        marginBottom: 195
    },
};

const PhotoPageStyle = styled("div")({

    "& .priceMainBox2": {
        justifyContent: 'space-between',
        display: 'flex',
        width: '94%',
    },
    "& .priceBox2": {
        width: '392',
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
        borderRadius: '16px',
    },
    "& .priceTitle2": {
        lineHeight: "44px",
        fontWeight: 700,
        fontSize: "36px",
        letterSpacing: '-1%',
        fontFamily: 'Poppins',
        color: '#1C2110',
        marginBottom: '5px',
        marginTop: '40px',
        textAlign: 'center'
    },
    "& .priceSubTitle2": {
        lineHeight: "26px",
        fontWeight: 400,
        fontSize: "18px",
        fontFamily: 'Poppins',
        color: '#1C2110',
        marginBottom: '20px',
        textAlign: 'center'
    },
    "& .priceCenterDiv2": {
        height: '140px',
        backgroundColor: "#8CA74B",
        textAlign: 'center'
    },

    "& .priceCenterBox2": {
        backgroundColor: "#557C29",
        height: '140px'
    },
    "& .priceTag2": {
        lineHeight: "56px",
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: "64px",
        color: '#FFFFFF',
        textAlign: 'center',
        letterSpacing: '-1.5%',
        padding: '13px',
    },
    "& .dollar2": {
        lineHeight: "56px",
        fontWeight: 300,
        fontSize: "32px",
        fontFamily: 'Inter',
        color: '#FFFFFF',
        letterSpacing: '-1.5%',
    },
    "& .monthText2": {
        lineHeight: "28px",
        fontWeight: 400,
        fontSize: "20px",
        color: '#FFFFFF',
        marginBottom: '20px',
        textAlign: 'center',
        fontFamily: 'Poppins',
        display: "block",
        alignItems: "center",
    },
    "& .storageText2": {
        lineHeight: "28px",
        fontWeight: 400,
        fontSize: "20px",
        fontFamily: 'Poppins',
        color: '#1C2110',
        marginBottom: '40px',
        textAlign: 'center',
        marginTop: '20px',
    },
    "& .startBtn2": {
        backgroundColor: '#8CA74B',
        borderRadius: '8px',
        marginTop: '20px',
        marginBottom: '48px',
        padding: '10px',
        marginLeft: "auto",
        marginRight: "auto",
        display: "block",
        width: '182px',
        "& .Muibutton-label": {
            lineHeight: "24px",
            fontWeight: 700,
            fontFamily: 'Poppins',
            color: '#FFFFFF',
            fontSize: "16px",
            textAlign: 'center',
        }
    }
})




// Customizable Area End
