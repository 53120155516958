import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  IconButton,
  Card,
  CardContent,
  // Customizable Area Start
  Divider,
  Modal,
  FormControl,
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { subscriptionLogo, peopleGroup, circleImage, subscriptionBackground, frGroup, downArrow, profileIcon, homeIcon, diversity } from "./assets";
import Footer from "../../../components/src/Footer.web";
import { outlineCheck } from "../../../blocks/landingpage/src/assets";
import HeaderDropdown from "../../../components/src/HeaderDropdown.web";

import DOMPurify from 'dompurify';


interface Plan {
  id: string;
  icon: any;
  title: string;
  subtitle: string;
  price: string;
  priceSuffix: string;
  storage: string;
  description: string;
  boxStyle: React.CSSProperties;
  buttonStyle: React.CSSProperties;
}


const bankNames = [
  "Bank of America",
  "Chase",
  "Wells Fargo",
  "Citi",
  "Capital One",
  "PNC Bank",
  "US Bank",
  "TD Bank",
  "BB&T",
  "SunTrust",
  "Fifth Third Bank",
  "HSBC",
  "Charles Schwab",
  "Ally Bank",
  "KeyBank",
  "Regions Bank",
  "Santander Bank",
  "BMO Harris Bank",
  "Huntington Bank",
  "M&T Bank",
  "Citizens Bank",
  "American Express",
  "Navy Federal Credit Union",
  "USAA",
  "Synchrony Bank",
  "Comerica",
  "Zions Bank",
  "First Republic Bank",
  "Silicon Valley Bank",
  "Discover Bank",
  "Goldman Sachs Bank USA",
  "Morgan Stanley Bank",
  "TD Ameritrade",
  "E*TRADE Bank",
  "Barclays Bank",
  "BancorpSouth",
  "BOK Financial",
  "Frost Bank",
  "Bank of the West",
  "BMO Financial Group",
  "Webster Bank",
  "Old National Bank",
  "UMB Financial Corporation",
  "People's United Financial",
  "FNB Corporation",
  "Pinnacle Financial Partners",
  "First National Bank",
  "Valley National Bank",
  "City National Bank",
  "East West Bank",
  "Cullen/Frost Bankers",
  "Synovus Bank",
  "Bank OZK",
  "Prosperity Bank",
  "Texas Capital Bank",
  "Wintrust Financial",
  "South State Bank",
  "Independent Bank",
  "Renasant Bank",
  "BankUnited",
  "First Citizens Bank",
  "United Community Bank",
  "Cadence Bank",
  "Western Alliance Bank",
  "Banner Bank",
  "Columbia Bank",
  "Great Western Bank",
  "Pacific Premier Bank",
  "First Horizon Bank",
  "Central Bank",
  "Southwest Bank",
  "Commerce Bank",
  "TowneBank",
  "Heritage Bank",
  "Bank of Hawaii",
  "BancFirst",
  "Glacier Bank",
  "Simmons Bank",
  "UMB Bank",
  "Heartland Bank",
  "Tri Counties Bank",
  "Trustmark National Bank",
  "First Financial Bank",
  "First Interstate Bank",
  "Provident Bank",
  "City Bank",
  "Hanmi Bank",
  "Mechanics Bank",
  "NBT Bank",
  "OceanFirst Bank",
  "Old Second National Bank",
  "Park National Bank",
  "Peoples Bank",
  "Pinnacle Bank",
  "PlainsCapital Bank",
  "PNC Bank",
  "Popular Bank",
  "Rabobank",
  "Raymond James Bank",
  "Republic Bank",
  "Rockland Trust",
  "Santander Bank",
  "Scotiabank",
  "Simmons Bank",
  "Southern Bank",
  "Sterling National Bank",
  "Synovus Bank",
  "TCF Bank",
  "TD Bank",
  "The Northern Trust Company",
  "Trustmark Bank",
  "UMB Bank",
  "Union Bank",
  "United Bank",
  "Valley National Bank",
  "Webster Bank",
  "Wells Fargo",
  "Western Alliance Bank",
  "Wintrust Bank",
  "Zions Bank"
];





// Customizable Area End

import SubscriptionbillingController, {
  Props,
} from "./SubscriptionbillingController";


export default class Subscriptionbilling extends SubscriptionbillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { expanded } = this.state;
    return (
      // Customizable Area Start
      <Box>
        <Box style={{ backgroundColor: '#FEFFFB' }}>

          <HeaderDropdown data-test-id="header" editProfileClick={this.profileClickButton} subscriptionClick={this.subscriptionClickButton} paymentClick={this.paymentClickBotton}/>

          <Box style={{ position: 'relative' }}>
            <Box style={{ padding: 20 }}>
              <Box style={{ maxWidth: 1209, width: '100%', margin: 'auto', marginTop: 100 }}>
                <Typography style={webStyle.headerText}>Plans that fit your scale</Typography>
                <Typography style={webStyle.subHeaderText}>Simple, transparent pricing that grows with you. Try any plan free for 05 days.</Typography>
                <img src={circleImage} style={{ position: 'absolute', top: 10, right: 0, width: 160 }} />
                <img src={subscriptionBackground} style={{ position: 'absolute', bottom: 10, left: 0, width: 162 }} />
              </Box>
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'center', gap: 40, marginTop: 50 }}>


              <SubscriptionStyle>
                <Box className="priceMainBox">
                  {this.state.plansandPricing.map(plan => (
                    <Box key={plan.id} className="priceBox">
                      <Typography className="priceTitle">{plan.name}</Typography>

                      <Box className="priceCenterDiv">
                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                          <Typography className="dollar"> $ </Typography>
                          <Typography className="priceTag"> {plan.price}</Typography>
                        </Box>
                        <Typography className="monthText">/ Month</Typography>
                      </Box>

                      <Box sx={{ display: "flex", alignItems: "center", mb: 2, justifyContent: 'center' }}>
                        <Typography className="priceSubTitle"

                          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(plan.details) }}

                        >
                        </Typography>

                      </Box>
                      <Box style={{ display: "flex", justifyContent: "center" }}>
                        <img src={outlineCheck} alt="check" />
                        <Typography style={{ color: '#1C2110', marginLeft: 10, fontSize: 18 }}>
                          {plan.storage} {plan.storage_unit} of storage
                        </Typography>
                      </Box>
                      {/* <Typography className="storageText"><img src={outlineCheck} alt="check icon" /> {plan.storage} {plan.storage_unit} of storage</Typography> */}
                      <Button className="startBtn" data-test-id={`priceBtn${plan.id}`}
                        onClick={this.handleOpenModal.bind(this, plan)}
                      >Start now</Button>
                    </Box>
                  ))}


                </Box>
              </SubscriptionStyle>

            </Box>
          </Box>
        </Box>
        <Box style={{ textAlign: 'center', maxWidth: '1030px', width: '100%', margin: 'auto', marginTop: 80 }}>
          <img src={subscriptionLogo} style={{ width: 56, height: 56, marginTop: 20 }} />
          <Typography style={webStyle.discoverText}>Discover our Plans</Typography>
          <Typography style={webStyle.subPlanText}>Explore the various subscription options tailored to meet your needs. From individual to crew plans, unlock exclusive features such as photo storage, family tree tools, recipe sharing, and more. </Typography>
          <img src={peopleGroup} />
        </Box>
        <Box style={{ gap: 64, maxWidth: 1280, width: '100%', margin: 'auto', display: 'flex', justifyContent: 'center', marginTop: 100, alignItems: 'center' }}>
          <Box>
            <Typography style={webStyle.joinCommunityText}>Join our community
              of 500+ individuals and start your journey with My Kinships today
            </Typography>
            <Typography style={webStyle.trailText}>Start your 05-day free trial today.</Typography>
            <Button
              style={webStyle.styleNowButton2}
            >
              <Typography style={webStyle.startNowText}>
                Get started
              </Typography>
            </Button>
          </Box>
          <img src={frGroup} style={{ maxWidth: 576, width: '100%', margin: 'auto' }} />
        </Box>
        <Box style={{ maxWidth: 1240, width: '100%', margin: 'auto' }}>
          <Typography style={webStyle.frequentlyText}>Frequently asked questions</Typography>
          <Typography style={webStyle.simpleText}>Simple, transparent pricing that grows with you. Try any plan free for 30 days.</Typography>
        </Box>
        
         <Container maxWidth="md" style={{ marginBottom: 100, marginTop: 60 }}>
        <div style={{ width: '100%', marginTop: 50, maxWidth: 920, margin: 'auto' }}>
          {this.state.faqsData.map((faq:any, index:any) => (
            <Box
              key={index}
              style={{
                borderRadius: '4px',
                marginBottom: 16,
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  borderBottom: expanded !== index ? '1px solid #D6D3D1' : 'none',
                }}
                data-test-id="coverAccordion1TestId"
                onClick={this.handleAccordion.bind(index,this)}
              >
                <Typography
                  style={{ flex: 1, fontFamily: 'Poppins', fontWeight: 700, fontSize: 18 }}
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.question) }} >
                  
                </Typography>
                <IconButton
                  size="small"
                  data-test-id="coverAccordion2"
                  aria-expanded={expanded === index}
                  aria-label="show more"
                  style={{ marginLeft: 'auto', transition: 'transform 0.3s ease' }}
                >
                  <img
                    src={downArrow}
                    style={{
                      width: 24,
                      transform: expanded === index ? 'rotate(180deg)' : 'rotate(0deg)',
                    }}
                    alt="Toggle"
                  />
                </IconButton>
              </Box>
              {expanded === index && (
                <Box style={{ padding: '12px', borderBottom: '1px solid #D6D3D1' }}>
                  <Typography
                    style={{ fontFamily: 'Poppins', fontWeight: 400, fontSize: 16, color: '#5D6063' }}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.answer) }}>
                    
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
        </div>
      </Container>
        <Footer />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  cancleButton: {
    backgroundColor: '#C2E176',
    borderRadius: '8px',
    height: '56px',
    width: '195px',
    marginTop: '32px',
  },
  cancelText: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#8CA74B',
    textTransform: 'none',
  },
  styleNowButton: {
    backgroundColor: '#8CA74B',
    borderRadius: '8px',
    padding: '10px',
    marginTop: '20px',
    marginBottom: '48px',
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    width: '182px',
  },
  styleNowButton2: {
    backgroundColor: '#8CA74B',
    borderRadius: '8px',
    padding: '10px',
    marginTop: '20px',
    display: "block",
    width: '182px',
  },
  startNowText: {
    lineHeight: "24px",
    fontWeight: 700,
    fontSize: "16px",
    textTransform: "none",
    fontFamily: 'Poppins',
    color: '#FFFFFF',
  } as React.CSSProperties,

  headerView: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    borderBottom: '1px solid #E7E5E4'
  },
  headerText: {
    fontSize: 36,
    fontFamily: 'Poppins',
    fontWeight: 700,
    color: '#1C1917'
  },
  subHeaderText: {
    fontSize: 18,
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#5D6063'
  },
  firstCardText: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 30,
    fontFamily: 'Poppins',
    fontWeight: 700,
    color: '#1C1917'
  },
  secondCardText: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 18,
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#1C1917'
  },
  greenBox: {
    backgroundColor: '#8CA74B',
    padding: '16px',
    width: '100%',
    marginLeft: '-16px',
    marginRight: '-16px',
    marginTop: 20
  },
  greenBox2: {
    backgroundColor: '#557C29',
    padding: '16px',
    width: '100%',
    marginLeft: '-16px',
    marginRight: '-16px',
    marginTop: 20
  },
  monthText: {
    fontSize: 20,
    fontWeight: 400,
    fontFamily: 'Poppins',
    display: 'flex',
    justifyContent: 'center',
    color: '#fff'
  },
  subscriptionText: {
    fontSize: 18,
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#475467',
    padding: 20,
    minHeight: '250px',
    textAlign: "initial"
  },
  dollerSign: {
    fontFamily: 'Inter',
    fontWeight: 300,
    fontSize: 32,
    lineHeight: '32px',
    color: '#fff'
  },
  discoverText: {
    fontSize: 36,
    fontWeight: 700,
    fontFamily: 'Poppins',
    color: '#1C1917',
    marginTop: 20
  },
  subPlanText: {
    fontSize: 18,
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#475467',
    marginTop: 10
  },
  joinCommunityText: {
    fontSize: 48,
    fontWeight: 700,
    fontFamily: 'Poppins',
    color: '#1C2110',
    width: 576,
    display: 'flex',
    alignItems: 'center'
  },
  trailText: {
    fontSize: 18,
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#475467',
    marginTop: 10
  },
  simpleText: {
    fontSize: 18,
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#5D6063'
  },
  frequentlyText: {
    fontSize: 36,
    fontFamily: 'Poppins',
    fontWeight: 700,
    color: '#1C1917',
    marginTop: 100
  },
  titleTexts: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C2110"
  },
  titleTexts2: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C2110",
    marginTop: 10
  },
  continueButton: {
    backgroundColor: "#8CA74B",
    borderRadius: '8px',
    height: '56px',
    width: '195px',
    marginTop: '32px',
  },
  continueText: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    textTransform: 'none'
  },
  logoStyle: {
    position: 'absolute' as 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    width: '24px',
    height: '24px',
  },
};



const CustomErrorMessage = styled(Box)({
  fontFamily: "Poppins",
  fontWeight: 400,
  fontSize: "12px",
  color: 'red',
  marginTop: '5px',
})


const SubscriptionStyle = styled("div")({
  "& .priceMainBox": {
    display: 'flex',
    justifyContent: 'space-between',
    width: '94%',
    marginLeft: 100,
    marginRight: 100
  },
  "& .priceBox": {
    width: '392',
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
    borderRadius: '16px',
  },
  "& .priceTitle": {
    lineHeight: "44px",
    fontWeight: 700,
    fontFamily: 'Poppins',
    color: '#1C2110',
    fontSize: "36px",
    letterSpacing: '-1%',
    marginBottom: '5px',
    marginTop: '40px',
    textAlign: 'center'
  },
  "& .priceSubTitle": {
    lineHeight: "26px",
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#1C2110',
    marginBottom: '20px',
    textAlign: 'center'
  },
  "& .priceCenterDiv": {
    height: '140px',
    backgroundColor: "#8CA74B",
    textAlign: 'center'
  },

  "& .priceCenterBox": {
    height: '140px',
    backgroundColor: "#557C29",
  },
  "& .priceTag": {
    fontWeight: 500,
    fontSize: "64px",
    lineHeight: "56px",
    fontFamily: 'Inter',
    color: '#FFFFFF',
    textAlign: 'center',
    letterSpacing: '-1.5%',
    padding: '13px',
  },
  "& .dollar": {
    lineHeight: "56px",
    fontWeight: 300,
    fontFamily: 'Inter',
    fontSize: "32px",
    color: '#FFFFFF',
    letterSpacing: '-1.5%',
  },
  "& .monthText": {
    fontWeight: 400,
    fontSize: "20px",
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    marginBottom: '20px',
    lineHeight: "28px",
    textAlign: 'center',
    display: "block",
    alignItems: "center",
  },
  "& .storageText": {
    lineHeight: "28px",
    fontWeight: 400,
    fontFamily: 'Poppins',
    fontSize: "20px",
    color: '#1C2110',
    marginBottom: '40px',
    textAlign: 'center',
    marginTop: '20px',
  },
  "& .startBtn": {
    backgroundColor: '#8CA74B',
    borderRadius: '8px',
    padding: '10px',
    marginTop: '20px',
    marginLeft: "auto",
    marginBottom: '48px',
    marginRight: "auto",
    display: "block",
    width: '182px',
    "& .Muibutton-label": {
      lineHeight: "24px",
      fontWeight: 700,
      fontSize: "16px",
      color: '#FFFFFF',
      textAlign: 'center',
      fontFamily: 'Poppins',
    }
  }
});


// Customizable Area End
