import React from "react";
import {
    // Customizable Area Start
    Box,
    Button,
    Typography,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { styled } from "@material-ui/styles"
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer.web";
import SiteMapController, {
    Props,
} from "./SiteMapController";
import { backIcon } from "../../blogpostsmanagement/src/assets";
// Customizable Area End

export default class SiteMap extends SiteMapController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <SiteMapStyle>
                <Header data-test-id="header" onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick} />
                <Box style={{ marginBottom: '15%' }}>
                    <Box style={{ display: 'flex', marginLeft: '100px', marginTop: '100px' }}>
                        <Button className="backBtn" data-test-id="handleBackButton" onClick={this.backBtn}>
                            <img style={{ color: '#1C2110' }} src={backIcon} alt="Back" />
                        </Button>
                        <Typography className="sitemapTitle">SITEMAP</Typography>
                    </Box>
                    <Box className="uppperMainDiv">
                        <Box style={{ width: '350px' }}>
                            <Typography className="titleText">Home</Typography>
                            <Box className="buttonsDiv">
                                <Button className='MenuBtn' data-test-id="handleHomeClick" onClick={this.handleHomeClick}>Home</Button>
                                <Button className='MenuBtn'>Photos</Button>
                                <Button className='MenuBtn'>Moments</Button>
                                <Button className='MenuBtn'>Family Tree</Button>
                                <Button className='MenuBtn'>Recipes</Button>
                                <Button className='MenuBtn' data-test-id="handlePlanClick" onClick={this.handlePlanClick}>Plans and pricing</Button>
                            </Box>
                        </Box>
                        <Box style={{ width: '350px' }}>
                            <Typography className="titleText">Tools</Typography>
                            <Box className="buttonsDiv">
                                <Button className='MenuBtn' data-test-id="handlePrivacyClick1" onClick={this.handlePrivacyClick}>Privacy Policy</Button>
                                <Button className='MenuBtn' data-test-id="handlePrivacyClick2" onClick={this.handlePrivacyClick}>Terms and Conditions</Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="uppperMainDiv">
                        <Box style={{ width: '350px' }}>
                            <Typography className="titleText">Connect</Typography>
                            <Box className="buttonsDiv">
                                <Button className='MenuBtn' data-test-id="handleNewsLetterClick" onClick={this.handleNewsLetterClick}>Newsletter</Button>
                                <Button className='MenuBtn' data-test-id="handleBlogClick" onClick={this.handleBlogClick}>Blog</Button>
                                <Button className='MenuBtn' data-test-id="handleContactusClick" onClick={this.handleContactusClick}>Contact us</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Footer />
            </SiteMapStyle>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const SiteMapStyle = styled("div")({
    height: '100vw',
    "& .sitemapTitle": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "28px",
        color: "#78716C",
        marginLeft: '20px'
    },
    "& .MenuBtn": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "28px",
        color: "#1C2110",
        marginTop: '5px',
        marginBottom: '5px',
        '& .MuiButton-label': {
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: "20px",
            lineHeight: "28px",
            color: "#1C2110",
            textTransform: "none",
        },
    },
    "& .titleText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "32px",
        letterSpacing: '-0.5%',
        color: "#1C2110",
        marginBottom: '15px'
    },
    "& .uppperMainDiv": {
        display: 'flex',
        padding: '20px',
        marginLeft: '165px',
        marginTop: '30px'
    },
    "& .buttonsDiv": {
        display: 'flex',
        flexDirection: 'column',
        alignItems: "start"
    }
})