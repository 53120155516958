import React from "react";

import {
  Box,
  Button,
  Typography,
  TextField,
  // Customizable Area Start
  FormControlLabel,
  FormControl,
  IconButton,
  Select,
  Switch,
  SwitchProps,
  Popover,
  Snackbar,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Datepicker from "react-datepicker";
import { styled } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "react-datepicker/dist/react-datepicker.css";
import Divider from '@material-ui/core/Divider';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MenuItem from '@mui/material/MenuItem';
import 'react-phone-input-2/lib/style.css';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Footer from "../../../components/src/Footer.web"
import CloseIcon from '@material-ui/icons/Close';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Webcam from "react-webcam";
import CircleSharpIcon from '@mui/icons-material/CircleSharp';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import { ErrorMessage, Formik } from "formik";
export const configJSON = require("./config");
import HeaderDropdown from "../../../components/src/HeaderDropdown.web";
import CustomisableEditProfileController, {
  Props,
} from "./CustomisableEditProfileController.web";
import { Alert } from "@mui/material";
import { BackArrowBlack, avatar, imgUpload, leave2, leave1, logo, fail, check, cancel } from "./assets";
// Customizable Area End



// Customizable Area Start
const CustomizedButton = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 54,
  height: 28,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(25px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#8CA74B',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: "gray"
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 24,
    height: 24,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const states = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" }
];

const cities = [
  { value: "NYC", label: "New York City" },
  { value: "LA", label: "Los Angeles" },
  { value: "Chicago", label: "Chicago" },
  { value: "Houston", label: "Houston" },
  { value: "Phoenix", label: "Phoenix" },
  { value: "Philadelphia", label: "Philadelphia" },
  { value: "San Antonio", label: "San Antonio" },
  { value: "San Diego", label: "San Diego" },
  { value: "Dallas", label: "Dallas" },
  { value: "San Jose", label: "San Jose" },
  { value: "Austin", label: "Austin" },
  { value: "Jacksonville", label: "Jacksonville" },
  { value: "Fort Worth", label: "Fort Worth" },
  { value: "Columbus", label: "Columbus" },
  { value: "San Francisco", label: "San Francisco" },
  { value: "Charlotte", label: "Charlotte" },
  { value: "Indianapolis", label: "Indianapolis" },
  { value: "Seattle", label: "Seattle" },
  { value: "Denver", label: "Denver" },
  { value: "Washington", label: "Washington" },
  { value: "Boston", label: "Boston" },
  { value: "El Paso", label: "El Paso" },
  { value: "Nashville", label: "Nashville" },
  { value: "Detroit", label: "Detroit" },
  { value: "Oklahoma City", label: "Oklahoma City" },
  { value: "Portland", label: "Portland" },
  { value: "Las Vegas", label: "Las Vegas" },
  { value: "Memphis", label: "Memphis" },
  { value: "Louisville", label: "Louisville" },
  { value: "Baltimore", label: "Baltimore" },
  { value: "Milwaukee", label: "Milwaukee" },
  { value: "Albuquerque", label: "Albuquerque" },
  { value: "Tucson", label: "Tucson" },
  { value: "Fresno", label: "Fresno" },
  { value: "Sacramento", label: "Sacramento" },
  { value: "Mesa", label: "Mesa" },
  { value: "Atlanta", label: "Atlanta" },
  { value: "Kansas City", label: "Kansas City" },
  { value: "Colorado Springs", label: "Colorado Springs" },
  { value: "Miami", label: "Miami" },
  { value: "Raleigh", label: "Raleigh" },
  { value: "Omaha", label: "Omaha" },
  { value: "Long Beach", label: "Long Beach" },
  { value: "Virginia Beach", label: "Virginia Beach" },
  { value: "Oakland", label: "Oakland" },
  { value: "Minneapolis", label: "Minneapolis" },
  { value: "Tampa", label: "Tampa" },
  { value: "Tulsa", label: "Tulsa" },
  { value: "Arlington", label: "Arlington" },
  { value: "New Orleans", label: "New Orleans" }
];

// Customizable Area End

export default class CustomisableEditProfile extends CustomisableEditProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <UserProfileStyle>
          {this.state.isCaptureEnable ? (
            <div style={{ margin: 0, padding: 0 }}>
              <Box className="headerDiv">
                <Box className="cameraBox">
                  <Button className="backBtn" data-test-id="handleBackButton" onClick={this.handleCaptureDisable}>
                    <img style={{
                      color: '#1C2110', height: '32px',
                      width: '32px'
                    }} src={BackArrowBlack} alt="Back" />
                  </Button>
                  <Typography className="cameraText">Camera</Typography>
                </Box>
                <Box className="profileDiv">
                  <img src={avatar} />
                  <IconButton aria-label="delete" size="small">
                    <ExpandMoreIcon fontSize="inherit" />
                  </IconButton>
                </Box>
              </Box>
              <div ref={this.fullscreenRef}>
                <Webcam
                  style={{ height: "calc(100vh - 82px - 154px)", width: "100vw" }}
                  ref={this.webcamRef}
                  audio={false}
                />
              </div>
              <Box className="bottomCameraDiv">
                <Box>
                  <Typography className="photoTextTypo">PHOTO</Typography>
                  <Button onClick={this.capture} style={{ borderRadius: '80px', border: '3px solid #8CA74B', height: '62px', width: '62px' }}>
                    <CircleSharpIcon style={{ height: '60px', color: '#8CA74B', width: '60px' }} />
                  </Button>
                </Box>
                {this.state.userImg && (
                  <Box style={{ display: 'flex' }} onClick={this.captureProfile} data-test-id="capturePhotoBox">
                    <img src={this.state.userImg} style={{ height: '100px', width: '100px' }} />
                  </Box>
                )}
              </Box>
            </div>
          ) : (
            <>
              <HeaderDropdown data-test-id="headerComponent" editProfileClick={this.profileClickButton} subscriptionClick={this.subscriptionClickButton} paymentClick={this.paymentClickBotton} />
              <Box>
                <Box className="navigationBox">
                  <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <Button color="inherit" href="/">
                      Profile
                    </Button>
                    <Typography color="textPrimary">Edit Profile</Typography>
                  </Breadcrumbs>
                </Box>
                <Box className="editProfileBox">
                  <Button className="backButton" data-test-id="backButton">
                    <img style={{ color: 'black' }} src={BackArrowBlack} alt="Back" />
                  </Button>
                  <Typography className="titleText">Edit profile</Typography>
                </Box>
              </Box>
              <Box className="mainDiv">
                <Box className="innerDiv">
                  {this.state.deviceSupportError && (
                    <Alert
                      className="alertPhotoContainer"
                      severity="error"
                      icon={false}
                      style={{
                        display: 'block',
                        marginTop: '32px'
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        {this.state.deviceSupportError}
                        <IconButton style={{ marginLeft: 'auto', color: '#DC2626' }} size="small" onClick={() => this.setState({ deviceSupportError: '' })}>
                          <CloseSharpIcon fontSize="inherit" />
                        </IconButton>
                      </div>
                    </Alert>
                  )}
                  {this.state.removePhotoError && (
                    <Alert
                      className="alertPhotoContainer"
                      severity="error"
                      icon={false}
                      style={{
                        display: 'block',
                        marginTop: '32px'
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        {this.state.removePhotoError}
                        <IconButton style={{ marginLeft: 'auto', color: '#DC2626' }} size="small" onClick={() => this.setState({ removePhotoError: '' })}>
                          <CloseSharpIcon fontSize="inherit" />
                        </IconButton>
                      </div>
                    </Alert>
                  )}
                  <Box className="topBox">
                    <img src={leave2} />
                    <img src={leave1} />
                  </Box>
                  <Box style={{ position: 'relative' }}>
                    <Box className="avatarImgBox">
                      <img className="profilePhoto" src={this.state.selectedImg || avatar} />
                    </Box>
                    <Button className="cameraIConBox" data-test-id="handleEditPhotoBtn" onClick={this.handleEditPhoto}>
                      <CameraAltOutlinedIcon style={{ color: 'white' }} />
                      <Typography className="editPicText">Edit picture</Typography>
                    </Button>
                  </Box>
                  {this.state.showSuccessSnackbar ? (
                    <Snackbar
                      style={{ borderRadius: '8px' }}
                      open={this.state.showSuccessSnackbar}
                      autoHideDuration={6000}
                    >
                      <Alert style={{ backgroundColor: '#F8FFE7', color: '#1C1917', fontFamily: 'Poppins', fontWeight: 400, fontSize: '16px' }}
                        icon={<img style={{ width: '20px' }} src={check} />}
                        severity={this.state.snackbarType} >
                        {this.state.snackbarMessage}
                      </Alert>
                    </Snackbar>
                  ) : (
                    <Snackbar
                      style={{ borderRadius: '8px' }}
                      open={this.state.showSuccessSnackbar}
                      autoHideDuration={6000}
                    >
                      <Alert style={{ backgroundColor: '#F8FFE7', color: '#1C1917', fontFamily: 'Poppins', fontWeight: 400, fontSize: '16px', display: 'flex', alignItems: 'center' }}
                        icon={<img style={{ width: '20px', height: '20px' }} src={cancel} />}
                        severity={this.state.snackbarType} >
                        {this.state.snackbarMessage}
                        <Button onClick={this.closeSuccessSnackbar} style={{
                          backgroundColor: '#557C29', borderRadius: '8px', color: '#FFFFFF', fontFamily: 'Poppins', fontWeight: 700, fontSize: '14px', textTransform: 'none', marginLeft: '5px'
                        }}>Try again</Button>
                      </Alert>
                    </Snackbar>
                  )}

                  <Formik
                    data-test-id="formikProfileForm"
                    enableReinitialize
                    initialValues={{
                      fullName: this.state.fullName,
                      email: this.state.email,
                      birthDate: this.state.birthDate,
                      contactNumber: this.state.contactNumber,
                      address: this.state.address,
                      city: this.state.city,
                      state: this.state.state,
                      zipCode: this.state.zipCode,
                    }}
                    validationSchema={this.userProfileSchema}
                    onSubmit={values => {
                      this.submitProfileDetails(values);
                    }}
                  >
                    {({ handleSubmit, handleChange, values, setFieldValue, handleReset }) => (
                      <form className="formContainer" onSubmit={handleSubmit}>
                        <Box className="btnsDiv">
                          <Button className="cancleBtn" data-test-id="cancelBtn"
                            onClick={() => {
                              this.handleCancleBtn();
                              handleReset();
                            }} >
                            Cancel
                          </Button>
                          <Button className="saveChangesBtn" type="submit" data-test-id="saveChangesBtn">
                            Save changes
                          </Button>
                        </Box>
                        {this.state.emptyDataError && (
                          <Alert
                            className="alertContainer"
                            severity="error"
                            icon={false}
                            style={{
                              display: 'block',
                              marginTop: '32px'
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                              {this.state.emptyDataError}
                              <IconButton style={{ marginLeft: 'auto', color: '#DC2626' }} size="small" onClick={() => this.setState({ emptyDataError: '' })}>
                                <CloseSharpIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          </Alert>
                        )}
                        <Box className="mainFormContainer">
                          <Box className="infoMainDiv">
                            <Box style={{ width: '30%' }}>
                              <Typography className="basicInfoText">Basic info</Typography>
                            </Box>
                            <Box style={{ width: '70%' }}>
                              <Typography className="textFieldTitles">Full name</Typography>
                              <TextField
                                value={values.fullName}
                                onChange={handleChange}
                                data-test-id="nameField"
                                type="text"
                                className="placeholderTexts"
                                autoComplete="off"
                                name="fullName"
                                placeholder="Your Full name"
                                variant="outlined"
                                fullWidth />
                              <ErrorMessage name="fullName" component="div" className="errorMessage" />
                              <Typography className="textFieldTitles">Birthday</Typography>
                              <Datepicker
                                selected={values.birthDate}
                                maxDate={new Date()}
                                onChange={date => setFieldValue('birthDate', date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                name="birthDate"
                                data-test-id="birthDate"
                                className="placeholderTexts"
                                dropdownMode="select"
                                placeholderText="MM/DD/YYYY"
                                dateFormat="MM/dd/yyyy"
                                customInput={<TextField variant="outlined" fullWidth />}
                                onKeyDown={(e) => {
                                  const input = (e.target as HTMLInputElement).value;
                                  const currentYear = new Date().getFullYear();
                                  if (e.key === 'Backspace') {
                                    setFieldValue('birthDate', null);
                                    return;
                                  }
                                  if (isNaN(Number(e.key))) {
                                    e.preventDefault();
                                    return;
                                  }
                                  if (input.length === 2 && parseInt(input) > 12) {
                                    (e.target as HTMLInputElement).value = '';
                                    return;
                                  }
                                  if (input.length === 2) {
                                    (e.target as HTMLInputElement).value = `${input}/`;
                                    return;
                                  }
                                  if (input.length === 5) {
                                    if (parseInt(input.substring(3, 5)) > 31) {
                                      (e.target as HTMLInputElement).value = input.substring(0, 3);
                                      return;
                                    }
                                    (e.target as HTMLInputElement).value = `${input}/`;
                                    return;
                                  }
                                  if (input.length === 10) {
                                    if (parseInt(input.substring(6, 10)) > currentYear) {
                                      (e.target as HTMLInputElement).value = input.substring(0, 6);
                                      return;
                                    }
                                  }
                                  if (input.length > 10) {
                                    (e.target as HTMLInputElement).value = input.substring(0, 10);
                                    return;
                                  }
                                }}
                              />
                              <ErrorMessage name="birthDate" component="div" className="errorMessage" />
                              <Typography className="textFieldTitles">E-mail</Typography>
                              <TextField
                                value={values.email}
                                onChange={handleChange}
                                data-test-id="emailField"
                                type="text"
                                className="placeholderTexts"
                                autoComplete="off"
                                name="email"
                                placeholder="Your Email name"
                                variant="outlined"
                                fullWidth />
                              <ErrorMessage name="email" component="div" className="errorMessage" />
                              {this.state.apiError && (
                                <Typography component="div" className="errorMessage">
                                  {this.state.apiError}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                          <div className="horizontalLine"></div>
                          <Box className="infoMainDiv">
                            <Box style={{ width: '30%' }}>
                              <Typography className="basicInfoText">Contact information</Typography>
                            </Box>
                            <Box style={{ width: '70%' }}>
                              <Typography className="textFieldTitles">Contact number</Typography>
                              <PhoneInput
                                inputProps={{ name: "contactNumber" }}
                                data-test-id="contactNumber"
                                inputStyle={{ width: '100%', height: '11%' }}
                                country={"us"}
                                value={values.contactNumber}
                                onChange={(value) => setFieldValue("contactNumber", value)} />
                              <ErrorMessage name="contactNumber" component="div" className="errorContactMessage" />
                              <Typography className="textFieldTitles" style={{ marginTop: '15px' }}>Address</Typography>
                              <TextField
                                value={values.address}
                                onChange={handleChange}
                                data-test-id="address"
                                type="text"
                                className="placeholderTexts"
                                autoComplete="off"
                                name="address"
                                placeholder="Enter Your Address"
                                variant="outlined"
                                fullWidth />
                              <ErrorMessage name="address" component="div" className="errorMessage" />
                              <Typography className="textFieldTitles">City</Typography>
                              <FormControl variant="outlined" style={{ marginBottom: '20px' }} fullWidth>
                                <Select
                                  data-test-id="city"
                                  value={values.city}
                                  onChange={handleChange}
                                  name="city"
                                  className="placeholderTexts"
                                  variant="outlined"
                                  autoComplete="off"
                                  displayEmpty
                                >
                                  <MenuItem value="" disabled>Select a City</MenuItem>
                                  {cities.map((city) => (
                                    <MenuItem key={city.value} value={city.value}>
                                      {city.label}
                                    </MenuItem>
                                  ))}

                                </Select>
                                <ErrorMessage name="city" component="div" className="errorMessage" />
                              </FormControl>
                              <Box style={{ display: 'flex', gap: '2%', justifyContent: 'space-between', marginTop: '-15px' }}>
                                <Box style={{ width: '48%' }}>
                                  <Typography className="textFieldTitles"> State</Typography>
                                  <FormControl variant="outlined" style={{ marginBottom: '20px' }} fullWidth>
                                    <Select
                                      value={values.state}
                                      onChange={handleChange}
                                      name="state"
                                      data-test-id="state"
                                      className="placeholderTexts"
                                      variant="outlined"
                                      autoComplete="off"
                                      displayEmpty
                                    >
                                      <MenuItem value="" disabled>Select a City</MenuItem>
                                      {states.map((state) => (
                                        <MenuItem key={state.value} value={state.value}>
                                          {state.label}
                                        </MenuItem>
                                      ))}

                                    </Select>
                                    <ErrorMessage name="state" component="div" className="errorMessage" />
                                  </FormControl>
                                </Box>
                                <Box style={{ width: '48%' }}>
                                  <Typography className="textFieldTitles">Zip code</Typography>
                                  <TextField
                                    value={values.zipCode}
                                    onChange={handleChange}
                                    data-test-id="zipCode"
                                    type="text"
                                    className="placeholderTexts"
                                    autoComplete="off"
                                    name="zipCode"
                                    placeholder="Enter Your Zip code"
                                    variant="outlined"
                                    fullWidth />
                                  <ErrorMessage name="zipCode" component="div" className="errorMessage" />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <div className="horizontalLine2"></div>
                          <Box className="infoMainDiv">
                            <Box style={{ width: '30%' }}>
                              <Typography className="basicInfoText">Notifications</Typography>
                            </Box>
                            <Box style={{ width: '70%' }}>
                              <FormControlLabel
                                className="notificaionLableText"
                                labelPlacement='start'
                                label="Subscribe to newsletters, promotions, and updates from My Kinships."
                                control={<CustomizedButton
                                  data-test-id="handleToggleChangeBtn"
                                  checked={this.state.newsToggleChecked}
                                  onChange={(event) => this.handleToggleChange(event)} />} />
                            </Box>
                          </Box>
                          <div className="horizontalLine"></div>
                          <Button className="deleteBtnDiv" type="submit" onClick={this.handleDeleteBtn} data-test-id="deleteBtn">
                            <DeleteOutlinedIcon />
                            <Typography className="deleteText">Delete Account</Typography>
                          </Button>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Box>

              <Popover
                data-test-id="handleCloseEditPhoto"
                className="photoModal"
                open={this.state.EditPhotoClick}
                onClose={this.handleCloseEditPhoto}
                style={{
                  top: 470,
                  left: 420,
                  position: 'absolute'
                }}
              >
                <PopoverPhotoContent>
                  <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button style={{ textTransform: 'none' }} data-test-id="openUploadModal" onClick={this.openUploadModal}>
                      <FileUploadOutlinedIcon className="photoListIcon" />
                      <Typography className="photoText">Upload photo</Typography>
                    </Button>
                    <Button style={{ textTransform: 'none' }} data-test-id="handleCaptureEnable" onClick={this.handleCaptureEnable}>
                      <CameraAltOutlinedIcon className="photoListIcon" />
                      <Typography className="photoText"> Take a picture</Typography>
                    </Button>
                    <Button style={{ textTransform: 'none' }} data-test-id="handleRemovePhotoBtn" onClick={this.handleRemovePhotoBtn}>
                      <DeleteOutlineIcon className="photoListIcon" />
                      <Typography className="photoText">Remove photo</Typography>
                    </Button>
                  </Box>
                </PopoverPhotoContent>
              </Popover>

              <Popover
                className="dialogContainer"
                open={this.state.showModal}
                onClose={this.closeDeleteModel}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
              >
                <PopoverContent>
                  <Box className="headerBox">
                    <Typography className="deleteAccText">Delete account</Typography>
                    <IconButton
                      data-test-id='deleteIcon'
                      className="closeIcon"
                      onClick={this.closeDeleteModel}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Divider style={{ width: '120%', marginTop: '25px', marginBottom: '25px', color: '#E7E5E4' }} />
                  <Box className="modalMainDiv">
                    <DeleteOutlineIcon style={{ color: 'white', height: '65px', width: '65px' }} />
                  </Box>
                  <Box style={{ textAlign: 'center', marginBottom: '20px', marginLeft: '10px', marginRight: '10px', marginTop: '40px' }}>
                    <Typography className="mainTextModal">Are you sure you want to delete your account?</Typography>
                    <Typography className="subTextModal">If you proceed to delete your account, you will have the option to recover it for a period of 30 days, after that, it will be completely deleted.</Typography>
                  </Box>
                  <Divider style={{ width: '120%', marginTop: '10px', marginBottom: '25px', color: '#E7E5E4' }} />
                  <Box style={{ display: 'flex', width: '100%', gap: '15px' }}>
                    <Button onClick={this.closeDeleteModel} className="keepBtn" data-test-id="keepBtn">
                      Keep it
                    </Button>
                    <Button onClick={this.handleConfirmDeleteBtn} className="deleteBtn" data-test-id="handleDeleteBtn">
                      Yes, delete it
                    </Button>
                  </Box>
                </PopoverContent>
              </Popover>

              <Popover
                open={this.state.showDeleteConfirmModal}
                onClose={this.closeDeleteConfirmModal}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
              >
                <PopoverDeleteContent>
                  <Box className="headerBox">
                    <Typography className="deleteAccText">Delete account</Typography>
                    <IconButton
                      data-test-id='closeModalIcon'
                      className="closeIcon"
                      onClick={this.closeDeleteConfirmModal}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Divider style={{ width: '120%', marginTop: '25px', marginBottom: '30px', color: '#E7E5E4' }} />
                  <Box className="modalMainDiv">
                    <DeleteOutlineIcon style={{ color: 'white', height: '65px', width: '65px' }} />
                  </Box>
                  <Box style={{ textAlign: 'center', marginBottom: '20px', marginLeft: '10px', marginRight: '10px', marginTop: '40px' }}>
                    <Typography className="mainTextModal">The account has been successfully deleted</Typography>
                  </Box>
                </PopoverDeleteContent>
              </Popover>

              <Popover
                className="dialogContainer"
                open={this.state.uploadMedia}
                onClose={this.closeUploadModal}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
              >
                <PopoverProfileContent>
                  <Box className="headerProfileBox">
                    <Typography className="pictureText">Profile picture selection</Typography>
                    <IconButton
                      data-test-id='closeIcon'
                      className="closeIcon"
                      onClick={this.closeUploadModal}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Divider style={{ marginTop: '10px', marginBottom: '25px', color: '#E7E5E4' }} />
                  <Box
                    data-test-id="mediaDragBox"
                    className="imgInnerBox"
                    onDrop={this.handleMediaDrop}
                    onDragOver={this.handleMediaDragOver}
                    onDragLeave={this.handleMediaDragLeave}
                    onDragEnter={this.handleMediaDragEnter}
                  >
                    <input
                      data-test-id="handleFileInput"
                      type="file"
                      ref={this.fileInputRef}
                      style={{ display: 'none' }}
                      onChange={this.handleFileChange}
                      accept="image/*" />
                    {this.state.userImg ? (
                      <img src={this.state.userImg} alt="Selected" style={{ width: '152px', height: '152px' }} />
                    ) : (
                      <>
                        <img src={imgUpload} />
                        <Typography className="uploadImgText">Upload image</Typography>
                        <Typography className="dragImgText">Drag an image here or</Typography>
                        <Button className="browseBtn" data-test-id="browseBtn" onClick={this.handleUploadButtonClick}>
                          <AddCircleOutlineOutlinedIcon style={{ height: '17px', width: '17px', color: '#557C29' }} />
                          <Typography className="browseFileText">Browse file to upload</Typography>
                        </Button>
                      </>
                    )}
                  </Box>
                  <Box style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '20px' }}>
                    <Button className="cancelBtn" onClick={this.closeUploadModal}>
                      Cancel
                    </Button>
                    <Button className="saveBtn" data-test-id="saveProfileClickBtn" onClick={() => this.saveProfileClick(this.state.userImg)}>
                      Save as profile picture
                    </Button>
                  </Box>
                </PopoverProfileContent>
              </Popover>

              <Dialog open={this.state.showMediaModal} onClose={this.handleMediaCloseModal} PaperProps={{
                style: {
                  height: 555,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  borderRadius: 8,
                  borderBottomRightRadius: 50,
                },
              }} >
                <DialogTitle style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins' }}>
                  <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Unsupported format or image size</Typography>
                  <IconButton onClick={this.handleMediaCloseModal}
                    aria-label="close"
                    style={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: '#666',
                    }}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Box style={{
                      textAlign: 'center',
                      backgroundColor: '#F87171',
                      borderRadius: '80px',
                      height: '130px',
                      width: '130px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                      <img src={fail} style={{ color: 'white', height: '65px', width: '65px' }} />
                    </Box>
                  </div>
                  <Box style={{ textAlign: 'center', marginBottom: '20px', marginLeft: '10px', marginRight: '10px', marginTop: '40px' }}>
                    <Typography style={{
                      fontFamily: "Poppins",
                      fontWeight: 700,
                      fontSize: "20px",
                      lineHeight: "28px",
                      color: "#1C2110",
                      alignItems: 'center'
                    }} >Unable to read your files.</Typography>
                    <Typography style={{
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#1C2110",
                      alignItems: 'center',
                      marginTop: '30px'
                    }}>Your photos couldn't be uploaded. Photos should be less than X MB and saved as JPG, PNG, GIF, TIFF, or HEIF files. </Typography>
                  </Box>
                </DialogContent>
                <Divider />
                <DialogActions style={{ display: 'flex', justifyContent: 'center', paddingBottom: 30, marginTop: '30px' }}>
                  <Button data-test-id="handleMediaCloseModal" onClick={this.handleMediaCloseModal} style={{
                    border: '1px solid #C2E176',
                    backgroundColor: '#F8FFE7',
                    width: '300px',
                    height: '56px',
                    fontSize: "16px",
                    fontWeight: 700,
                    textTransform: "none",
                    fontFamily: "Poppins",
                    lineHeight: "24px",
                    color: '#8CA74B'
                  }}>Cancel</Button>
                  <Button onClick={this.tryAgainClick} style={{
                    backgroundColor: '#8CA74B',
                    width: '300px',
                    height: '56px',
                    fontSize: "16px",
                    fontWeight: 700,
                    textTransform: "none",
                    fontFamily: "Poppins",
                    lineHeight: "24px",
                    color: 'white'
                  }}  >Try again </Button>
                </DialogActions>
              </Dialog>

            </>
          )}
        </UserProfileStyle>
        {!this.state.isCaptureEnable ? (
          <Footer />
        ) : (
          null
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const PopoverProfileContent = styled(Box)(() => ({
  width: "400px",
  height: 'auto',
  padding: "20px",
  alignItems: "center",
  "@media(max-width: 768px)": {
    padding: "10px",
    height: "240px !important",
  },
  "& .headerProfileBox": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  "& .pictureText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    color: '#000000',
    letterSpacing: '0.5%'
  },
  "& .imgInnerBox": {
    border: '2px dashed #D6D3D1',
    borderRadius: '5px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  "& .uploadImgText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: '#1C2110',
  },
  "& .dragImgText": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: '#202020',
  },
  "& .browseBtn": {
    '& .MuiButton-label': {
      fontFamily: "Poppins",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
      color: '#557C29',
      textTransform: 'none'
    }
  },
  "& .browseFileText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: '#557C29',
    marginLeft: '2px'
  },
  "& .yourPhotosText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: '#1C2110',
    marginTop: '20px'
  },
  "& .imgsBox": {
    display: 'flex',
    gap: '16px',
    marginTop: '10px'
  },
  "& .viewBtn": {
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "none",
    fontFamily: "Poppins",
    lineHeight: "22px",
    color: '#557C29',
    '& .MuiButton-label': {
      textTransform: 'none'
    }
  },
  "& .cancelBtn": {
    borderRadius: '8px',
    border: '1px solid #C2E176',
    backgroundColor: '#F8FFE7',
    width: '180px',
    height: '44px',
    '& .MuiButton-label': {
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 700,
      color: '#8CA74B',
      fontFamily: "Poppins",
      lineHeight: "24px",
    }
  },
  "& .saveBtn": {
    width: '180px',
    backgroundColor: '#8CA74B',
    borderRadius: '8px',
    height: '44px',
    '& .MuiButton-label': {
      fontWeight: 700,
      fontSize: "16px",
      fontFamily: "Poppins",
      textTransform: "none",
      color: 'white',
      lineHeight: "24px",
    }
  }
}));

const PopoverPhotoContent = styled(Box)(() => ({
  padding: '5px',
  marginRight: '30px',
  "& .photoText": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#0F172A",
    padding: '6px',
    marginLeft: '5px',
  },
  "& .photoListIcon": {
    height: '18px',
    width: '18px'
  }
}));

const PopoverDeleteContent = styled(Box)(() => ({
  width: "365px",
  display: 'flex',
  height: "310px",
  padding: "20px",
  alignItems: "center",
  flexDirection: "column",
  position: 'relative',
  "@media(max-width: 768px)": {
    padding: "10px",
    height: "240px !important",
  },
  "& .headerBox": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  "& .deleteAccText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    color: '#1C2110',
    letterSpacing: '0.5%'
  },
  "& .closeIcon": {
    height: '14px',
    width: '14px',
    color: '#44403C'
  },
  "& .mainTextModal": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    color: '#1C2110',
    marginLeft: '20px', marginRight: '20px'
  },
  "& .modalMainDiv": {
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: '#34D399',
    borderRadius: '75px',
    justifyContent: 'center',
    height: '100px',
    width: '100px',
  },
}));

const PopoverContent = styled(Box)(() => ({
  width: "400px",
  display: 'flex',
  height: "500px",
  padding: "20px",
  alignItems: "center",
  flexDirection: "column",
  position: 'relative',
  "@media(max-width: 768px)": {
    padding: "10px",
    height: "240px !important",
  },
  "& .modalMainDiv": {
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: '#8CA74B',
    borderRadius: '75px',
    justifyContent: 'center',
    height: '100px',
    width: '100px',
  },
  "& .closeIcon": {
    height: '14px',
    width: '14px',
    color: '#44403C'
  },
  "& .headerBox": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  "& .deleteAccText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    color: '#1C2110',
    letterSpacing: '0.5%'
  },
  "& .mainTextModal": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    color: '#1C2110',
    marginLeft: '20px', marginRight: '20px'
  },
  "& .subTextModal": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: '#1C2110',
    marginLeft: '6px',
    marginRight: '6px',
    marginTop: '16px'
  },
  "& .keepBtn": {
    backgroundColor: '#F8FFE7',
    height: '44px',
    width: '180px',
    borderRadius: '8px',
    border: '1px solid #C2E176',
    '& .MuiButton-label': {
      fontSize: "16px",
      fontWeight: 700,
      color: '#8CA74B',
      textTransform: "none",
      fontFamily: "Poppins",
      lineHeight: "24px",
    }
  },
  "& .deleteBtn": {
    width: '180px',
    backgroundColor: '#8CA74B',
    borderRadius: '8px',
    height: '44px',
    '& .MuiButton-label': {
      fontSize: "16px",
      fontWeight: 700,
      color: 'white',
      textTransform: "none",
      fontFamily: "Poppins",
      lineHeight: "24px",
    }
  },
}));

const UserProfileStyle = styled("div")({
  "& .headerDiv": {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    borderBottom: '1px solid #E7E5E4'
  },
  "& .profileDiv": {
    display: 'flex'
  },
  "& .titleText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "40px",
    letterSpacing: "-0.5%",
    color: "#1C1917",
  },
  "& .photoModal": {
    padding: '30px'
  },
  "& .topBox": {
    backgroundColor: '#8CA74B',
    height: '217px',
    border: '1px solid #8CA74B',
    borderBottomRightRadius: '32px',
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  "& .mainDiv": {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  "& .innerDiv": {
    width: '70%',
  },
  "& .navigationBox": {
    padding: '25px',
    marginLeft: '70px'
  },
  "& .editProfileBox": {
    display: 'flex',
    marginLeft: '30px'
  },
  "& .cancleBtn": {
    border: '1px solid #C2E176',
    backgroundColor: '#F8FFE7',
    width: '148px',
    height: '44px',
    borderRadius: '8px',

    '& .MuiButton-label': {
      fontSize: "16px",
      fontWeight: 700,
      textTransform: "none",
      fontFamily: "Poppins",
      lineHeight: "24px",
      color: '#8CA74B'
    }
  },
  "& .saveChangesBtn": {
    backgroundColor: '#8CA74B',
    width: '148px',
    height: '44px',
    borderRadius: '8px',
    '& .MuiButton-label': {
      fontSize: "16px",
      fontWeight: 700,
      textTransform: "none",
      fontFamily: "Poppins",
      lineHeight: "24px",
      color: 'white'
    }
  },
  "& .btnsDiv": {
    display: 'flex',
    justifyContent: 'end',
    gap: '25px',
    marginTop: '-70px',
    marginRight: '39px'
  },
  "& .infoMainDiv": {
    display: 'flex'
  },
  "& .basicInfoText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: '#5D6063'
  },
  "& .textFieldTitles": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: '#1C2110',
    marginTop: '15px'
  },
  "& .notificaionLableText": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: '#1C2110',
    display: 'flex',
    justifyContent: 'space-between'
  },
  "& .MuiFormControlLabel-labelPlacementStart": {
    marginLeft: 0,
    marginRight: 0
  },
  "& .deleteBtnDiv": {
    color: '#DC2626',
    marginBottom: '65px',
    '& .MuiButton-label': {
      textTransform: 'none'
    }
  },
  "& .deleteText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: '#DC2626'
  },
  "& .profilePhoto": {
    height: '152px',
    width: '152px',
    borderRadius: '80px',
  },
  "& .avatarImgBox": {
    border: '4px solid white',
    borderRadius: '80px',
    transform: "translate(45px, -80px)",
    height: '152px',
    width: '152px',
  },
  "& .alertContainer": {
    borderRadius: '4px',
    border: '0px, 0px, 0px, 4px',
    borderLeft: '4px solid #DC2626',
    padding: "12px, 16px, 12px, 16px",
    fontSize: "12px",
    foneFamily: "Poppins",
    fontWeight: 400,
    marginLeft: '40px',
    marginRight: '39px',
    marginBottom: '20px'
  },
  "& .alertPhotoContainer": {
    borderRadius: '4px',
    border: '0px, 0px, 0px, 4px',
    borderLeft: '4px solid #DC2626',
    padding: "12px, 16px, 12px, 16px",
    fontSize: "12px",
    foneFamily: "Poppins",
    fontWeight: 400,
  },
  "& .cameraIConBox": {
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "#0000008a",
    borderRadius: '80px',
    height: '148px',
    width: '150px',
    display: 'flow',
    transform: "translate(50px, -75px)",
    '& .MuiButton-label': {
      textTransform: 'none'
    }
  },
  "& .editPicText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    color: '#FFFFFF'
  },
  "& .mainFormContainer": {
    marginLeft: '40px',
    marginRight: '39px'
  },
  "& .placeholderTexts": {
    marginBottom: '15px',
    marginTop: '5px'
  },
  "& .horizontalLine": {
    borderBottom: '1px solid #E2E8F0',
    borderRadius: '0.5px',
    marginTop: '32px',
    marginBottom: '32px'
  },
  "& .horizontalLine2": {
    borderBottom: '1px solid #E2E8F0',
    borderRadius: '0.5px',
    marginTop: '10px',
    marginBottom: '32px'
  },
  "& .errorMessage": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: '#DC2626',
    marginTop: '-12px'
  },
  "& .errorContactMessage": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: '#DC2626',
    marginTop: '2px'
  },
  "& .errorEmail": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: '#DC2626',
    backgroundColor: 'white'
  },
  "& .deleteIconBox": {
    borderRadius: '75px',
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    backgroundColor: '#8CA74B',
    height: '125px',
    width: '125px'
  },
  "& .dialogContainer": {
    boderRadius: '8px 8px 32px 8px',
    backgroundColor: 'white',
    height: '577px',
    width: '600px',
    "& .MuiPopover-paper": {
      boderRadius: '8px 8px 32px 8px',
      backgroundColor: 'white',
      height: '577px',
      width: '600px'
    },
  },
  "& .cameraText": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    color: '#1C2110',
    letterSpacing: '-0.12%'
  },
  "& .cameraBox": {
    display: 'flex',
    alignItems: 'center'
  },
  "& .bottomCameraDiv": {
    height: '150px',
    backgraoungColor: 'gray',
    display: 'flex',
    justifyContent: 'center'
  },
  "& .photoTextTypo": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    color: '#8CA74B',
    alignItems: 'center'
  }
})
// Customizable Area End