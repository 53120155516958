// Customizable Area Start
import React from "react";
import BlogPostsManagementController, { Props } from "./BlogPostsManagementController";
import { backIcon, firstImage, leftIcon, rightIcon } from "./assets";
import { Box, Card, CardActionArea, CardContent, CardMedia, IconButton, InputBase, Paper, Typography } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer.web";
import DOMPurify from 'dompurify';
import HeaderDropdown from "../../../components/src/HeaderDropdown.web";

export default class BlogpostsDetails extends BlogPostsManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { currentIndex, showBlogs } = this.state;
    const visibleCards = this.state.blogDetails.slice(currentIndex, currentIndex + 3);

    return (
      // Customizable Area Start
      <Box>
         {this.handleLogin() != "" ? (
          <HeaderDropdown
            data-test-id="headerComponenttestid" 
            editProfileClick={this.profileClickButton}
            subscriptionClick={this.subscriptionClickBtn}
            paymentClick={this.paymentClickBotton}
          />
          ) : (
          <Header onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick} />
        )}
        <Box sx={webStyle.container}>
          <Box sx={webStyle.header}>
            <img src={backIcon} style={webStyle.backIcon} onClick={this.handleBackClick} data-test-id="backIconTest" />
            <Paper
              component="form"
              style={webStyle.searchIconStyle}
              onSubmit={this.handleSubmit}
            >
              <IconButton type="button" style={{ padding: "10px", color: "#8CA74B" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                style={{ marginLeft: 1, flex: 1, color: "#8CA74B" }}
                data-test-id="inputBoxId"
                placeholder="Search"
                value={this.state.searchText}
                onChange={(e) => this.handleChange(e)}
              />
              {this.state.searchText && (
                <IconButton data-test-id="clearBtn" onClick={this.handleClear} style={{ padding: "10px", color: "#8CA74B" }} aria-label="clear">
                  <ClearIcon />
                </IconButton>
              )}


            </Paper>
            <div style={{ fontFamily: "sans-serif" }}>
              <div
                style={{
                  margin: "0 auto",
                  width: "184px",
                  zIndex: 10000,
                  height: 40,
                  marginTop: 92,
                  position: "relative",
                  marginLeft: 30,
                }}
              >
                <div
                  onClick={this.handleToggle.bind(this)}
                  style={{
                    ...webStyle.dropdownStyle,
                    color: "#8CA74B"
                  }}
                >
                  {this.state.selected}

                  <KeyboardArrowDownIcon style={{ color: "#8CA74B" }} />
                </div>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    left: 0,
                    boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.07)",
                    borderRadius: 8,
                    background: 'white',
                    display: this.state.isActive ? "block" : "none"
                  }}
                >
                  {this.state.categoriesData.map((option, index) => (

                    <div
                      key={index}
                      className="item"
                      style={{ padding: 10, cursor: "pointer", color: "#8CA74B" }}
                      onClick={
                        this.handleOptionSelect.bind(this, option)
                      }

                    >
                      {option.name}

                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Box>
          <Typography style={{ ...webStyle.blogText, borderBottom: '2px solid #78716C', marginTop: '5px', width: 62 }}>BLOG</Typography>
          <Box sx={webStyle.detailsPage}>
            <Typography style={webStyle.exploreInspire}>
              {showBlogs.title}
            </Typography>
          </Box>
          <Typography style={{ fontFamily: 'Poppins', fontWeight: 400, fontSize: 18 }}>
            {showBlogs.author}
          </Typography>
          <Box style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
            <Box
              style={{
                backgroundColor: "#E8FFB1",
                width: 90,
                borderRadius: 28
              }}
            >
              <Typography
                style={{ color: "#557C29", textAlign: "center", fontSize: 12, padding: 5 }}
              >

                {showBlogs.category_name}
              </Typography>
            </Box>
            <Box
              style={{
                backgroundColor: "#E8FFB1",
                width: 150,
                borderRadius: 28,
                marginLeft: 10,
              }}
            >

              <Typography
                style={{ color: "#557C29", textAlign: "center", fontSize: 12, padding: 5 }}
              >
                Entrepreneurship
              </Typography>
            </Box>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
            <img src={showBlogs.attachment_urls?.[0]?.url} style={{ width: "598px", height: "400px", borderRadius: "16px" }} />
          
          
            <Box style={{
              borderTop: '2px solid #8CA74B',
              borderBottom: '2px solid #8CA74B',
              borderRight: '2px solid #8CA74B',
              padding: '25px',
              marginLeft: '-2px',
              height: '300px',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '0 16px 16px 0'
            }}>
              <Typography style={{
                fontWeight: 700,
                fontSize: 17,
                fontFamily: 'Poppins',
                width: "362px"
              }}>
                {showBlogs.content}
              </Typography>
            </Box>
          </Box>

          <Box style={{
            justifyContent: 'center',
            alignSelf: 'center'
          }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 400, fontFamily: 'Poppins', marginTop: 20 }}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(showBlogs.description) }}
            />
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'center', marginTop: 20, position: 'relative' }}>
            <Typography style={{ fontSize: 30, fontWeight: 700, fontFamily: 'Poppins' }}>You may also like</Typography>
            <Box style={{ position: 'absolute', bottom: -10, width: 65, height: 2, backgroundColor: '#64748B' }} />
          </Box>
          <Box style={{ marginTop: 50, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '22px', width: '1100px', marginLeft: 50, alignSelf: 'center', marginBottom: '100px' }}>
            <img src={leftIcon} style={{ width: '32px', height: '32px', display: 'flex', alignSelf: 'center', cursor: 'pointer' }} onClick={this.handleLeftClick} data-test-id="leftIcon" />
            {visibleCards.map((data: any, index: any) => (
              <Card key={index} style={{ maxWidth: 316, height: 490 }} onClick={() => this.gotoDestinationScreen(data)}>
                <CardActionArea>
                  <Box
                    style={{
                      backgroundColor: "#E8FFB1",
                      width: 80,
                      borderRadius: 10,
                      marginLeft: 10,
                    }}
                  >
                    <Typography
                      style={{ color: "#557C29", textAlign: "center", fontSize: 12, marginTop: 20, }}
                    >
                      {data.category_name}
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <CardMedia
                      component="img"
                      style={{ borderRadius: 8, width: 270, marginTop: 20, height: 200 }}
                      image={data.attachment_urls[0].url}
                    />
                  </Box>
                  <CardContent>
                    <Typography
                      style={{
                        position: "relative",
                        fontSize: 17,
                        fontWeight: 700,
                        fontFamily: "Poppins",
                        marginLeft: 20,
                      }}
                    >
                      <span
                        style={{
                          height: "100%",
                          position: "absolute",
                          borderLeft: "7px solid #557C29",
                          top: 0,
                          left: -15,
                        }}
                      ></span>
                      {data.title}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 16,
                        marginTop: 20,
                        marginLeft: 25,
                        width: 230
                      }}
                    >
                      In the heart of our cozy kitchen, amidst the sweet, give them...
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
            <img src={rightIcon} style={{ width: '32px', height: '32px', display: 'flex', alignSelf: 'center', cursor: 'pointer' }} onClick={this.handleRightClick} data-test-id="rightIcon" />
          </Box>

        </Box >
        <Footer />
      </Box>
      // Customizable Area End
    );
  }
}

const webStyle = {
  backIcon: {
    width: 32,
    marginTop: 98,
    height: 32,
    position: 'absolute' as 'absolute',
    left: -50
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    maxWidth: '1016px',
    margin: 'auto'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  searchIconStyle: {
    padding: "2px 4px",
    display: "flex",
    width: 780,
    alignItems: "center",
    border: "1px solid #8CA74B",
    height: 30,
    marginTop: 108,
    borderRadius: "12px",
  },
  dropdownStyle: {
    cursor: "pointer",
    background: "white",
    alignItems: "center",
    display: "flex",
    padding: 7,
    justifyContent: "space-between",
    borderRadius: 8,
    fontWeight: 500,
    border: `1px solid #8CA74B`,
  },
  blogText: {
    color: '#78716C',
    fontWeight: 400,
    fontSize: 20,
    fontFamily: 'Poppins',
    marginLeft: "1%"
  },
  exploreInspire: {
    color: '#1C2110',
    fontFamily: 'Poppins',
    marginTop: 30,
    fontSize: 48,
    fontWeight: 700,
    // marginLeft: 'auto'
  },
  detailsPage: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }
};
// Customizable Area End
