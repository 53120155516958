import React from "react";
// Customizable Area Start
import { Box, Button, Typography, Container, IconButton, styled, Card, CardContent } from "@material-ui/core";
import Header from "../../../components/src/Header.web";
// Customizable Area End
import LandingPageController, {
    Props,
} from "./LandingPageController";
import { mainMomments, recipeDesign1, recipeBannerEmpty, recipeDesign2, recipeDesign3, recipeDesign4, recipeDesign5, recipeFeatureBg, downArrow, recipeDesign8, recipeDesign10, outlineCheck, familyTreeCircle, familyTreePin } from "./assets";
import Footer from "../../../components/src/Footer.web";

export default class MomentsPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { recipeFaq } = this.state
        return (
            //Merge Engine DefaultContainer
            <Box>
                <Header data-test-id="header" onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick} />
                <Box style={webStyles.backgroundImage}>
                    <Box style={{ width: "100%", maxWidth: "666px" }}>
                        <Typography style={webStyles.HeaderText}>Immortalizing Family Moments</Typography>
                        <Typography style={webStyles.subHeader}>
                            Only when we reach maturity do we realize that time actually flies and that memories are fleeting. However, unlike time, memories can be captured, seen, and relived in pictures or videos. MyKinship allows you to immortalize your favorite family moments, from overseas trips to major life milestones.
                        </Typography>
                        <Button type="submit" style={webStyles.continueButton}>
                            <Typography style={webStyles.continueText as any}>Get started</Typography>
                        </Button>
                    </Box>
                </Box>
                <Box>
                    <Box style={{ display: "flex", gap: 80, justifyContent: 'center', marginTop: 60, position: 'relative' }}>

                        <img src={recipeDesign5} style={{ maxWidth: "297px", width: "100%", position: 'absolute', right: 194, top: -120, zIndex: "-1" }} />
                        <img src={recipeDesign1} style={{ maxWidth: "297px", width: "100%", position: 'absolute', left: 0, top: -100, zIndex: -1 }} />
                        <img src={recipeDesign3} style={{ maxWidth: "342px", width: "100%", position: 'absolute', right: 0, top: -470, height: "356px" }} />

                        <Card style={{ width: "100%", maxWidth: "1133px", color: "#fff", borderRadius: 16, backgroundColor: "#fff", padding: 30 }}>
                            <CardContent>
                                <Typography style={{ fontWeight: 700, fontFamily: "Poppins", fontSize: 48, color: "#305328", textAlign: "center" }}>
                                    Immortalize Your Family Moments with Great Tools for Saving and Sharing
                                </Typography>
                                <Typography style={{ fontWeight: 400, fontFamily: "Poppins", fontSize: 20, color: "1C2110" }}>
                                    We live in a time that has revolutionized how we capture, store, and view memories. Each of us has a camera, a recorder, and a photo album in a single device in our pockets, with our memories at our fingertips. But what happens if the device stops working or you accidentally damage or lose it?
                                    All those memories, thought previously immortalized, are now gone—and data recovery services can be pricey. It’s much more affordable to immortalize your memories by storing them with MyKinship’s family photo storage service.
                                    By storing the pictures you deem important and dear, your memories remain truly immortalized and accessible, but more importantly, safe from any damage or loss suffered by personal electronics.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>

                <MomentPageStyle style={{ display: 'flex', marginTop: 60, justifyContent: 'center' }}>
                    <Box className="priceMainBoxMoment">
                        {this.state.subscriptionData.map(plan => (
                            <Box key={plan.id} className="priceBoxMoment">
                                <Typography className="priceTitleMoment">{plan.name}</Typography>
                                <Typography className="priceSubTitleMoment" dangerouslySetInnerHTML={{ __html: plan.details }} />
                                <Box className="priceCenterDivMoment">
                                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Typography className="dollarMoment"> $ </Typography>
                                        <Typography className="priceTagMoment"> {plan.price}</Typography>
                                    </Box>
                                    <Typography className="monthTextMoment">/ Month</Typography>
                                </Box>
                                <Typography className="storageTextMoment"><img src={outlineCheck} alt="check icon" /> {plan.storage} {plan.storage_unit} of storage</Typography>
                                <Button className="startBtnMoment" data-test-id={`priceBtn${plan.id}`} onClick={this.startNowBtn}>Start now</Button>
                            </Box>
                        ))}

                    </Box>
                </MomentPageStyle>
                <Box style={{ display: 'flex', marginTop: 100, position: "relative", justifyContent: 'center', }}>
                    <Box style={webStyles.backgroundImageFeature as any}>
                        <img src={recipeDesign8} style={{ width: "100%", position: 'absolute', maxWidth: "229px", right: 0, top: -80, height: 242 }} />
                        <img src={recipeDesign2} style={{ width: "100%", position: 'absolute', maxWidth: "115px", left: 0, top: 80, height: 122 }} />
                        <img src={recipeDesign10} style={{ width: "100%", maxWidth: "180px", position: 'absolute', top: 700, right: 0, height: 352 }} />
                        <img src={familyTreePin} style={{ width: "100%", maxWidth: "390px", position: 'absolute', left: 0, top: 1500 }} />
                        <Typography style={webStyles.bestFeatures}>How to Save Family Moments with MyKinship</Typography>
                        <Typography style={webStyles.bestFeatures2 as any}>MyKinship offers a seamless and secure way to manage your family’s digital photo collection. Here’s how it works</Typography>
                        <Box style={{ width: "100%", maxWidth: "1250px", marginTop: 70, display: 'flex', position: 'relative' }}>
                            <img src={familyTreeCircle} style={{ width: "100%", maxWidth: "190px", position: 'absolute', right: 520, top: 370, height: 180 }} />

                            <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={recipeBannerEmpty} style={{ maxWidth: "560px" }} />
                                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 50 }}>
                                    <Typography style={webStyles.familyText as React.CSSProperties}>
                                        Lorem ipsum
                                    </Typography>
                                    <Typography style={{ fontWeight: 400, fontFamily: "Poppins", fontSize: 20, color: "#1C2110" }}>Preserving family recipes is important. Not only do the flavors and smells transport us back to our own childhood, but they—like pictures—often have a story to tell. They evoke wonderful memories of those who wrote them down and keep traditions alive.
                                        MyKinship is a digital service that allows you to preserve your family recipes while capturing, sharing, and organizing family photos, immortalizing memories, and creating your own family
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ width: "100%", maxWidth: "1250px", marginTop: 70, display: 'flex', position: 'relative' }}>

                            <img src={familyTreeCircle} style={{ width: "100%", maxWidth: "210px", position: 'absolute', right: 522, top: 370, height: 180 }} />


                            <Box style={{ display: 'flex', justifyContent: 'center' }}>

                                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 50 }}>
                                    <Typography style={webStyles.familyText as React.CSSProperties}>
                                        Lorem ipsum
                                    </Typography>
                                    <Typography style={{ fontWeight: 400, fontFamily: "Poppins", fontSize: 20, color: "#1C2110", textAlign: "right" }}>Preserving family recipes is important. Not only do the flavors and smells transport us back to our own childhood, but they—like pictures—often have a story to tell. They evoke wonderful memories of those who wrote them down and keep traditions alive.
                                        MyKinship is a digital service that allows you to preserve your family recipes while capturing, sharing, and organizing family photos, immortalizing memories, and creating your own family
                                    </Typography>
                                </Box>
                                <img src={recipeBannerEmpty} style={{ maxWidth: "560px" }} />
                            </Box>
                        </Box>
                        <Box style={{ width: "100%", maxWidth: "1250px", marginTop: 70, display: 'flex', position: 'relative' }}>

                            <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={recipeBannerEmpty} style={{ maxWidth: "550px" }} />
                                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 50 }}>
                                    <Typography style={webStyles.familyText as React.CSSProperties}>
                                        Lorem ipsum
                                    </Typography>
                                    <Typography style={{ fontWeight: 400, fontFamily: "Poppins", fontSize: 20, color: "#1C2110" }}>Preserving family recipes is important. Not only do the flavors and smells transport us back to our own childhood, but they—like pictures—often have a story to tell. They evoke wonderful memories of those who wrote them down and keep traditions alive.
                                        MyKinship is a digital service that allows you to preserve your family recipes while capturing, sharing, and organizing family photos, immortalizing memories, and creating your own family
                                    </Typography>
                                </Box>
                            </Box>

                        </Box>

                    </Box>

                </Box>
                <Box style={{ maxWidth: 1240, margin: 'auto', width: '100%' }}>
                    <Typography style={webStyles.frequentlyText}>Frequently asked questions</Typography>
                    <Typography style={webStyles.simpleText2}>Simple, transparent pricing that grows with you. Try any plan free for 30 days.</Typography>
                </Box>
                <Container maxWidth="md" style={{ marginBottom: 100, marginTop: 60 }}>
                    <div style={{ width: '100%', marginTop: 50, margin: 'auto',maxWidth: "920px", }}>

                        {[1, 2, 3].map((index: any) => <Box
                            style={{
                                marginBottom: '16px',
                                borderRadius: '4px',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    padding: '12px',
                                    cursor: 'pointer',
                                    alignItems: 'center',
                                    borderBottom: !this.state.recipeFaq ? '1px solid #D6D3D1' : "none",
                                }}
                                data-test-id="coveracordian1"
                                onClick={this.handleAccordian}
                            >
                                <Typography style={{ flex: 1, fontFamily: 'Poppins', fontWeight: 700, fontSize: 18 }}>Is there a free trial available?</Typography>
                                <IconButton
                                    size="small"
                                    onClick={this.handleAccordian}
                                    aria-expanded={recipeFaq}
                                    data-test-id="coveracordian2"
                                    aria-label="show more"
                                    style={{ marginLeft: 'auto', transition: 'transform 0.3s ease' }}
                                >
                                    <img src={downArrow} style={{ width: 24, transform: 'rotate(0deg)' }} onClick={this.handleAccordian} />
                                </IconButton>
                            </Box>
                            {recipeFaq && (
                                <Box style={{ borderBottom: this.state.recipeFaq ? '1px solid #D6D3D1' : "none", padding: '12px' }}>
                                    <Typography style={{ flex: 1, fontFamily: 'Poppins', fontSize: 16, color: '#5D6063', fontWeight: 400 }}>
                                        Yes, you can try us for free for 5 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.
                                    </Typography>
                                </Box>
                            )}
                        </Box>)}
                    </div>
                </Container>
                <Box style={{ position: 'relative' }}>
                    <Box style={{ maxWidth: 1240, width: '100%', margin: 'auto' }}>
                        <img src={recipeDesign4} style={{ objectFit: 'contain', position: 'absolute', right: 0, height: 420, top: -80, zIndex: -1 }} />
                        <Typography style={webStyles.moreQuestions}>Reach Out to Us with Any Questions</Typography>
                        <Typography style={webStyles.moreQuestions2}>Losing your mementos, primarily pictures or videos, might be irreversible, so why not store them in a safe location? If you want to immortalize your memories and still have access to them anytime from anywhere, sign up for MyKinship.</Typography>
                    </Box>
                </Box>
                <Footer />
            </Box>

            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    container: {
        display: "grid",
        gridTemplateColumns: "3fr 7fr",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        width: "32rem",
        padding: "0.5rem 1rem",
        minHeight: "12rem",
        gap: "2rem",
    },
    HeaderText: {
        fontSize: 72,
        fontWeight: 400,
        fontFamily: 'Abygaer',
        color: '#305328',
        marginTop: 130,
        width: "100%",
        maxWidth: "700px"
    },
    subHeader: {
        color: '#305328',
        fontWeight: 400,
        fontSize: 24,
        fontFamily: "Poppins",
    },
    backgroundImage: {
        backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 70%), url(${mainMomments})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        maxWidth: "1635px",
        height: '800px',
        display: 'flex',
        justifyContent: "end",
    },
    continueButton: {
        backgroundColor: "#8CA74B",
        borderRadius: '8px',
        height: '56px',
        width: '292px',
        marginTop: '32px',
    },
    continueText: {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: 'none'
    },
    familyText: {
        color: "#1C1917",
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: 36
    },
    textContainer: {
        width: "100%",
        maxWidth: "656px"
    },
    familyText2: {
        color: "#305328",
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: 48,
        textAlign: 'right',
        width: "100%",
        maxWidth: "720px"
    },
    contentText: {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: 20,
        color: "#1C2110"
    },
    bestFeatures: {
        color: "#305328",
        fontWeight: 700,
        fontSize: 48,
        fontFamily: "Poppins",
        marginTop: 30
    },

    bestFeatures2: {
        color: "#5D6063",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: 20
    },
    backgroundImageFeature: {
        backgroundImage: `url(${recipeFeatureBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '1800px',
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center',
    },
    frequentlyText: {
        fontSize: 36,
        fontFamily: 'Poppins',
        fontWeight: 700,
        color: '#1C1917',
        marginTop: 100
    },
    simpleText2: {
        fontSize: 18,
        fontFamily: 'Poppins',
        fontWeight: 400,
        color: '#5D6063'
    },

    moreQuestions: {
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: 36,
        color: "#1C1917"
    },
    moreQuestions2: {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: 18,
        color: "#5D6063",
        marginTop: 10,
        marginBottom: 195
    },
};

const MomentPageStyle = styled("div")({

    "& .priceMainBoxMoment": {
        display: 'flex',
        justifyContent: 'space-between',
        width: '94%',
    },
    "& .priceBoxMoment": {
        width: '392',
        borderRadius: '16px',
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
    },
    "& .priceTitleMoment": {
        lineHeight: "44px",
        fontWeight: 700,
        fontSize: "36px",
        fontFamily: 'Poppins',
        color: '#1C2110',
        letterSpacing: '-1%',
        marginBottom: '5px',
        marginTop: '40px',
        textAlign: 'center'
    },
    "& .priceSubTitleMoment": {
        lineHeight: "26px",
        fontWeight: 400,
        fontSize: "18px",
        fontFamily: 'Poppins',
        color: '#1C2110',
        marginBottom: '20px',
        textAlign: 'center'
    },
    "& .priceCenterDivMoment": {
        backgroundColor: "#8CA74B",
        height: '140px',
        textAlign: 'center'
    },

    "& .priceCenterBoxMoment": {
        backgroundColor: "#557C29",
        height: '140px'
    },
    "& .priceTagMoment": {
        lineHeight: "56px",
        fontWeight: 500,
        fontSize: "64px",
        color: '#FFFFFF',
        textAlign: 'center',
        fontFamily: 'Inter',
        letterSpacing: '-1.5%',
        padding: '13px',
    },
    "& .dollarMoment": {
        lineHeight: "56px",
        fontWeight: 300,
        fontSize: "32px",
        color: '#FFFFFF',
        fontFamily: 'Inter',
        letterSpacing: '-1.5%',
    },
    "& .monthTextMoment": {
        lineHeight: "28px",
        fontSize: "20px",
        fontFamily: 'Poppins',
        fontWeight: 400,
        color: '#FFFFFF',
        marginBottom: '20px',
        textAlign: 'center',
        display: "block",
        alignItems: "center",
    },
    "& .storageTextMoment": {
        lineHeight: "28px",
        fontWeight: 400,
        fontSize: "20px",
        color: '#1C2110',
        marginBottom: '40px',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: '20px',
    },
    "& .startBtnMoment": {
        backgroundColor: '#8CA74B',
        borderRadius: '8px',
        padding: '10px',
        marginBottom: '48px',
        marginLeft: "auto",
        marginTop: '20px',
        marginRight: "auto",
        display: "block",
        width: '182px',
        "& .Muibutton-label": {
            fontWeight: 700,
            fontSize: "16px",
            fontFamily: 'Poppins',
            lineHeight: "24px",
            color: '#FFFFFF',
            textAlign: 'center',
        }
    }
})


// Customizable Area End
